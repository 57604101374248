import { TactileGroupUser } from '@introcloud/api-client';
import { ContactChip, mergeTranslations } from '@introcloud/blocks';
import { t } from 'i18n-js';
import React, { Fragment, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Paragraph } from 'react-native-paper';
import { useGroups } from '../hooks/useGroup';
import { useUser } from '../hooks/useUser';

mergeTranslations({
  en: {
    app: {
      home: {
        contacts: {
          title: 'Quick contacts',
          without_groups:
            "Once you've been assigned to a group, everyone in it will appear here. Check back later!",
          without_members:
            'At this moment your group contacts are not available.',
        },
      },
    },
  },

  nl: {
    app: {
      home: {
        contacts: {
          title: 'Snel contact',
          without_groups:
            'Als je eenmaal onderdeel bent van een groepje zul je al je groepsgenoten hier zien.',
          without_members:
            'Op dit moment is de contact informatie van je groepsgenoten niet beschikbaar.',
        },
      },
    },
  },
});

export function Contacts() {
  return (
    <Fragment>
      <List.Subheader style={styles.backgroundHeader}>
        {t('app.home.contacts.title')}
      </List.Subheader>

      <ContactChips />
    </Fragment>
  );
}

function ContactChips() {
  const { data: userSelf } = useUser();
  const { data: groups } = useGroups();

  const members = useMemo(
    () =>
      (groups || [])
        .reduce(
          (result, group) => result.concat(group.users),
          [] as TactileGroupUser[]
        )
        // Remove duplicates and self
        .filter(
          (item, index, self) =>
            self.findIndex((first) => first._id === item._id) === index &&
            userSelf?._id !== item._id
        )
        .filter(
          (user) =>
            !user.name.full.endsWith('=') &&
            (user.name.full.includes(' ') ||
              user.name.full.includes(user.name.first) ||
              user.name.first.length < 60)
        )
        // Remove invalid phonen numbers
        .map((member) => {
          if ((member.mobile.valid && member.mobile.value?.length) || 0 <= 5) {
            return { ...member, mobile: { ...member.mobile, valid: false } };
          }
          return member;
        }),
    [groups]
  );

  if (groups === undefined) {
    return null;
  }

  if (groups?.length === 0 || groups === null) {
    return null;
  }

  if (members.length === 0) {
    return (
      <Paragraph style={{ marginHorizontal: 16 }}>
        {t('app.home.contacts.without_members')}
      </Paragraph>
    );
  }

  return (
    <View style={styles.container}>
      {members.map((member) => {
        return <ContactChip contact={member} key={member._id} showChat />;
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginLeft: 12,
    marginRight: 12,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  backgroundHeader: {
    marginTop: 0,
  },
});
