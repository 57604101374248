import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';
import { ProfileScreen } from './ProfileScreen';

mergeTranslations({
  en: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Your chats',
        goto_balance: 'Balance',
        logout: 'Logout',
        change_image: 'Change image',
        change_about: 'Save about me',
        sessions_add: 'Add another',
        delete_account: 'Delete my account',
        delete_account_portal: 'Open portal',
        delete_account_cancel: 'Keep account',
        close_dialog: 'Close',
      },
      profile: {
        image: {
          caching:
            'It can take a while for your new image to show up everywhere.',
        },
        matching: {
          title: 'Your matching profile',
        },
        groups: {
          title: 'Your groups',
          no_groups:
            "Once you've been assigned to a group, everyone in it will appear here. Check back later!",
        },
        switcher: {
          title: 'Sessions',
        },
        delete: {
          title: 'Delete account',
          description:
            'It is possible to delete your account using the Tactile Web Portal. You can use the same credentials to login, after which you may request the removal of your account. Removing your account is permanent, and will revoke your access. Deleting your data is a manual process, but all deletion requests are honored.',
        },
      },
    },
  },

  nl: {
    app: {
      actions: {
        goto_group_chat: 'Chat',
        goto_chats: 'Jouw chats',
        goto_balance: 'Balans',
        logout: 'Uitloggen',
        change_image: 'Verander',
        change_about: 'Over mij opslaan',
        sessions_add: 'Toevoegen',
        delete_account: 'Verwijder mijn account',
        delete_account_portal: 'Open portaal',
        delete_account_cancel: ' Behoud account',
        close_dialog: 'Sluit',
      },
      profile: {
        image: {
          caching:
            'Het kan even duren voordat je wijziging overal zichtbaar is',
        },
        matching: {
          title: 'Jouw match profile',
        },
        groups: {
          title: 'Jouw groepje(s)',
          no_groups:
            'Als je eenmaal onderdeel bent van een groepje zul je al je groepsgenoten hier zien.',
        },
        switcher: {
          title: 'Sessies',
        },
        delete: {
          title: 'Verwijder account',
          description:
            'Het is mogelijk jouw account te verwijderen door gebruik te maken van ons Tactile Web Portal. Hier kan je dezelfde inloggegevens gebruiken. Je kan hier aanvragen je account te verwijderen. Het verwijderen is permanent, en we ontnemen je toegang. Het verwijderen is een handmatig process, maar alle aanvragen worden gehonoreerd.',
        },
      },
    },
  },
});

export function ProfileTab() {
  return <ProfileScreen asTab />;
}
