import {
  BalanceOverViewState,
  BalanceOverViewStateValid,
  fetchBalance,
  fetchPaymentMethods,
  PaymentMethodsState,
  TactileChatInfo,
  topUp,
  transferMoney,
} from '@introcloud/api-client';
import {
  AccentButton,
  localeSmartTimeString,
  OutlinedButton,
  TextButton,
  useLocale,
  useLocalization,
} from '@introcloud/blocks';
import {
  BlockData,
  ProvideBlockData,
  useBlockData,
  useWindowWidth,
} from '@introcloud/blocks-interface';
import { useIsFocused } from '@react-navigation/native';
import Color from 'color';
import { createURL } from 'expo-linking';
import { FetchMediaError } from 'fetch-media';
import { t } from 'i18n-js';
import React, {
  Fragment,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Linking, Platform, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  Dialog,
  IconButton,
  List,
  Menu,
  Portal,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useMutation, useQuery } from 'react-query';
import { useChatImage } from '../chats/useChatImage';
import { useChatInitials } from '../chats/useChatInitials';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { queryClient } from '../core/QueryCache';
import { ThemedSnackbar } from '../core/ThemedSnackbar';
import {
  useAuthorization,
  useCurrentDomain,
  useEndpoint,
  useSafeAuthorization,
} from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGroups, useGroupsDetached } from '../hooks/useGroup';

type BalanceCreditItem = BalanceOverViewStateValid['deposits'][number];
type BalanceDebitItem = BalanceOverViewStateValid['withdrawals'][number];
type Transaction = BalanceCreditItem | BalanceDebitItem;
type TypedTransaction =
  | ({ type: 'withdrawal' } & BalanceDebitItem)
  | ({ type: 'deposit' } & BalanceCreditItem);

export function PaymentScreen({ asTab }: { asTab?: boolean }) {
  const { values } = useCompanyTabs();
  const tab = useMemo(
    () => values.find((tab) => tab.tab === 'payment'),
    [values]
  );
  const title = useLocalization(
    tab?.titleLocalized || tab?.localizedTitle,
    tab?.title
  );
  const icon = useMemo(() => (tab ? tab.icon.name : 'account-cash'), [tab]);
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const isFocused = useIsFocused();
  const [toppingUp, toggleTopUp] = useReducer((prev) => !prev, false);
  const [sending, toggleSend] = useReducer((prev) => !prev, false);
  const [transactionsVisible, toggleTransactionsVisible] = useReducer(
    (prev) => !prev,
    false
  );

  const { data: groups, isLoading: isLoadingTargets } = useGroups({
    enabled: isFocused,
  });
  const hasTarget = groups?.some((group) => group.users.length > 0) || false;

  const { getImageUrl } = useBlockData();

  const provider = useMemo(() => {
    const getImageUrl = (
      imageId: string,
      targetSize:
        | 'icon_32'
        | 'icon_64'
        | 'icon_128'
        | 'icon_256'
        | 'icon_512'
        | 'icon_720'
        | 'icon_1440'
    ) => {
      if (!imageId || imageId.trim().length === 0) {
        return null;
      }

      return endpoint + `/image/${imageId}/${targetSize}`;
    };

    return { getImageUrl };
  }, [endpoint]);

  const { data, error, isLoading, dataUpdatedAt } = useQuery<
    BalanceOverViewState,
    FetchMediaError
  >(
    [authorization, 'app', 'balance'],
    ({ signal }) => fetchBalance(endpoint, authorization!, signal),
    {
      notifyOnChangeProps: ['data', 'error', 'isLoading', 'dataUpdatedAt'],
      enabled: Boolean(endpoint) && Boolean(authorization) && isFocused,
    }
  );

  const balance = data?.ok ? data.value.toString() : '   ';
  const withdrawals = data?.ok ? data.withdrawals : [];
  const deposits = data?.ok ? data.deposits : [];
  const transactions: TypedTransaction[] = useMemo(
    () =>
      ([] as TypedTransaction[])
        .concat(withdrawals.map((item) => ({ ...item, type: 'withdrawal' })))
        .concat(deposits.map((item) => ({ ...item, type: 'deposit' })))
        .sort((a, b) =>
          a.unix && b.unix ? b.unix - a.unix : b._id.localeCompare(a._id)
        ),
    [withdrawals, deposits]
  );

  const message = useRef('');
  const [showingMessage, setShowingMessage] = useState(false);
  const hideMessage = useCallback(
    () => setShowingMessage(false),
    [setShowingMessage]
  );
  const showMessage = useCallback(
    (next: string) => {
      message.current = next;
      setShowingMessage(true);
    },
    [message, setShowingMessage]
  );

  const balanceString = `${balance
    .slice(0, balance.length - 2)
    .padStart(1, '0')}.${balance
    .slice(balance.length - 2)
    .padStart(2, '0')} EUR`;

  return (
    <View style={{ flex: 1 }}>
      <EmptyState
        title={title || tab?.title || t('app.payment.title')}
        texts={{
          en: isLoading
            ? 'Retrieving your balance'
            : data
            ? `Your balance is ${balanceString}`
            : 'Your balance will be available later.',
          nl: isLoading
            ? 'Jouw balans aan het ophalen'
            : data
            ? `Jouw balans is ${balanceString}`
            : 'Jouw balans zal later beschikbaar zijn.',
        }}
        icon={icon}
        hidden={false}
      />
      <Header
        hideBack={asTab}
        title={title || tab?.title || t('app.payment.title')}
        subTitle={undefined}
        style={{ elevation: 2, zIndex: 2 }}
        showTranslate
      />
      <View
        style={{
          position: 'absolute',
          bottom: 56,
          width: '100%',
          zIndex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingHorizontal: 16,
        }}
      >
        {transactions.length > 0 ? (
          <View style={{ width: '100%', marginBottom: 16 }}>
            <TransactionsSummary
              transactions={transactions}
              onShow={toggleTransactionsVisible}
            />
            <Portal>
              <Dialog
                visible={transactionsVisible}
                onDismiss={toggleTransactionsVisible}
                style={{
                  maxWidth: 720,
                  alignSelf: 'center',
                  minWidth: 300,
                  width: '90%',
                }}
              >
                <TransactionsDialogContent
                  visible={toppingUp && isFocused}
                  transactions={transactions}
                />
                <Dialog.Actions>
                  <TextButton onPress={toggleTransactionsVisible}>
                    {t('app.actions.close_dialog')}
                  </TextButton>
                </Dialog.Actions>
              </Dialog>
            </Portal>
          </View>
        ) : null}
        <AccentButton
          icon="cash-plus"
          onPress={toggleTopUp}
          style={{ marginTop: 12 }}
        >
          {t('app.payment.actions.top-up')}
        </AccentButton>

        <View style={{ width: 4 }} />

        <AccentButton
          icon="cash-refund"
          onPress={toggleSend}
          loading={isLoadingTargets}
          disabled={!hasTarget}
          style={{ marginTop: 12 }}
        >
          {t('app.payment.actions.transfer')}
        </AccentButton>
      </View>
      <Portal>
        <Dialog
          visible={toppingUp}
          onDismiss={toggleTopUp}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 300,
            width: '90%',
          }}
        >
          <TopUpDialogContent visible={toppingUp && isFocused} />
          <Dialog.Actions>
            <TextButton onPress={toggleTopUp}>
              {t('app.payment.actions.negative_action')}
            </TextButton>
          </Dialog.Actions>
        </Dialog>

        <ProvideBlockData provider={provider}>
          <Dialog
            visible={sending}
            onDismiss={toggleSend}
            style={{
              maxWidth: 720,
              alignSelf: 'center',
              minWidth: 300,
              width: '90%',
            }}
          >
            <SendDialogContent
              getImageUrl={getImageUrl}
              showMessage={showMessage}
              visible={sending && isFocused}
            />
            <Dialog.Actions>
              <TextButton onPress={toggleSend}>
                {t('app.payment.actions.negative_action')}
              </TextButton>
            </Dialog.Actions>
          </Dialog>
        </ProvideBlockData>
      </Portal>
      <Portal>
        <ThemedSnackbar
          active={showingMessage}
          onDismiss={hideMessage}
          content={message.current}
          action={{ label: 'Ok', onPress: hideMessage }}
        />
      </Portal>
    </View>
  );
}

const DEFAULT_AMOUNT = [
  { euro: 15, cents: 1500 },
  { euro: 20, cents: 2000 },
  { euro: 25, cents: 2500 },
  { euro: 30, cents: 3000 },
  { euro: 40, cents: 4000 },
  { euro: 50, cents: 5000 },
  { euro: 60, cents: 6000 },
  { euro: 70, cents: 7000 },
  { euro: 80, cents: 8000 },
  { euro: 90, cents: 9000 },
  { euro: 100, cents: 10000 },
  { euro: 200, cents: 20000 },
];

function TransactionsSummary({
  transactions,
  onShow,
}: {
  transactions: TypedTransaction[];
  onShow(): void;
}) {
  const timestamp = useInaccurateTimestamp({ every: 15 * 1000 });

  const {
    colors: { primary },
  } = useTheme();
  const colorIsDark = new Color(primary).isDark();
  const onColor = colorIsDark ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)';

  const [last, ...rest] = transactions;
  const date = last.unix ? new Date(last.unix) : undefined;

  const value = (last.value / 100).toFixed(2);

  if (!date) {
    return (
      <Text
        style={{
          textAlign: 'center',
          marginHorizontal: 'auto',
          color: onColor,
        }}
      >
        <Text style={{ fontWeight: 'bold', color: onColor }}>{value} EUR</Text>{' '}
        EUR: {last.name}.{' '}
        <Text
          style={{
            textDecorationStyle: 'solid',
            textDecorationColor: onColor,
            textDecorationLine: 'underline',
            color: onColor,
          }}
          onPress={onShow}
        >
          {t('app.payment.actions.transactions')}
        </Text>
      </Text>
    );
  }

  return (
    <Text
      style={{ textAlign: 'center', marginHorizontal: 'auto', color: onColor }}
    >
      {localeSmartTimeString(date, timestamp)},{' '}
      <Text style={{ fontWeight: 'bold', color: onColor }}>{value} EUR</Text>:{' '}
      {last.name}.{' '}
      <Text
        style={{
          textDecorationStyle: 'solid',
          textDecorationColor: onColor,
          textDecorationLine: 'underline',
          color: onColor,
        }}
        onPress={onShow}
      >
        {t('app.payment.actions.transactions')}
      </Text>
    </Text>
  );
}

function TransactionsDialogContent({
  visible,
  transactions,
}: {
  visible: boolean;
  transactions: TypedTransaction[];
}) {
  const timestamp = useInaccurateTimestamp({ every: 60 * 1000 });
  const width = useWindowWidth();
  const last50 = transactions.slice(0, 50);

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{t('app.payment.transactions.title')}</Dialog.Title>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView contentContainerStyle={{ margin: 0, paddingHorizontal: 0 }}>
          {last50.map((transaction) => (
            <List.Item
              left={(props) => (
                <List.Icon
                  {...props}
                  style={[props.style, { marginLeft: 10, marginRight: 10 }]}
                  icon={
                    transaction.type === 'withdrawal'
                      ? 'cash-minus'
                      : transaction.value > 0
                      ? 'cash-plus'
                      : 'cash-refund'
                  }
                />
              )}
              right={(props) => (
                <List.Icon
                  icon="chevron-right"
                  {...props}
                  style={{ ...props.style, marginHorizontal: 8, opacity: 0 }}
                />
              )}
              key={transaction._id}
              title={({ ellipsizeMode, selectable, color, fontSize }) => (
                <Text
                  style={{ color, fontSize }}
                  selectable={selectable}
                  ellipsizeMode={ellipsizeMode}
                >
                  <Text
                    style={{
                      color,
                      fontSize,
                      fontWeight: 'bold',
                    }}
                    ellipsizeMode="tail"
                  >
                    {transaction.type === 'withdrawal' && transaction.value > 0
                      ? '-'
                      : ''}
                    {(transaction.value / 100).toFixed(2)} EUR
                  </Text>
                  <Text
                    style={{
                      color,
                      fontSize: fontSize - 6,
                    }}
                    ellipsizeMode="tail"
                  >
                    {transaction.unix
                      ? ` (${localeSmartTimeString(
                          new Date(transaction.unix),
                          timestamp
                        )})`
                      : ''}
                  </Text>
                </Text>
              )}
              description={transaction.name}
            />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function TopUpDialogContent({ visible }: { visible: boolean }) {
  useLocale();

  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const { data: company } = useRemoteCompany(useCurrentDomain());
  const [selectedMethod, setSelectedMethod] = useState<string | undefined>();

  const width = useWindowWidth();

  const amounts =
    ((company as any)?.settings?.payment?.amount as typeof DEFAULT_AMOUNT) ||
    DEFAULT_AMOUNT;
  const fee: { euro: number; cents: number } | undefined | null = (
    company as any
  )?.settings?.payment?.fee as typeof DEFAULT_AMOUNT[0];
  const [selectedAmount, setSelectedAmount] = useState<number | null>(2);

  const { data } = useQuery<PaymentMethodsState, FetchMediaError>(
    [endpoint, 'app', 'payment', 'methods'],
    () => fetchPaymentMethods(endpoint, authorization!),
    {
      notifyOnChangeProps: ['data'],
      enabled: Boolean(endpoint) && Boolean(authorization) && visible,
    }
  );

  const { mutateAsync: startTopUp } = useMutation<
    unknown,
    FetchMediaError,
    {
      amount: number;
      paymentMethod: string;
      bankId?: string | undefined;
    }
  >(
    ['app', 'payment', 'top-up'],
    async ({ amount, paymentMethod, bankId }) => {
      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await topUp(endpoint, authorization, {
        methodId: paymentMethod,
        bankId,
        cents: __DEV__ ? 1 : amount,
        returnUrl: createURL('/payment'),
      });

      if (result.ok) {
        if (Platform.OS === 'web') {
          const a = document.createElement('a');
          a.href = result.url;
          a.click();
        } else {
          return Linking.openURL(result.url);
        }
      }
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },
    }
  );

  if (!data?.ok) {
    return (
      <Fragment>
        <Dialog.Title>{t('app.payment.top-up.title')}</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{t('app.payment.top-up.title')}</Dialog.Title>

      <View style={{ position: 'absolute', right: 12, top: 18 }}>
        <Menu
          anchor={
            <OutlinedButton onPress={() => setSelectedAmount(null)}>
              {selectedAmount !== null && amounts[selectedAmount]
                ? `${amounts[selectedAmount].euro} EUR`
                : t('app.payment.top-up.label')}
            </OutlinedButton>
          }
          onDismiss={() => setSelectedAmount(2)}
          visible={selectedAmount === null}
        >
          {amounts.map((amount, index) => (
            <Menu.Item
              key={index}
              title={`${amount.euro} EUR`}
              onPress={() => setSelectedAmount(index)}
            />
          ))}
        </Menu>
      </View>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView contentContainerStyle={{ margin: 0, paddingHorizontal: 0 }}>
          {selectedMethod ? (
            <List.Item
              left={() => <List.Icon icon="chevron-left" />}
              title={t('app.payment.top-up.change')}
              onPress={() => setSelectedMethod(undefined)}
            />
          ) : null}
          {selectedMethod
            ? (
                data.methods.find(
                  (method) => method.methodId === selectedMethod
                )?.banks || []
              ).map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  onPress={() =>
                    startTopUp({
                      paymentMethod: selectedMethod!,
                      bankId: method.bankId,
                      amount: amounts[selectedAmount!].cents,
                    })
                  }
                />
              ))
            : null}
          {selectedMethod
            ? null
            : data.methods.map((method) => (
                <List.Item
                  key={method.name}
                  left={() => <List.Icon icon={{ uri: method.imageUrl }} />}
                  title={method.name}
                  onPress={
                    method.banks.length > 0
                      ? () => setSelectedMethod(method.methodId)
                      : () =>
                          startTopUp({
                            paymentMethod: method.methodId,
                            amount: amounts[selectedAmount!].cents,
                          })
                  }
                />
              ))}
        </ScrollView>
        {fee ? (
          <View style={{ paddingHorizontal: 16, paddingVertical: 8 }}>
            <Text style={{ fontStyle: 'italic' }}>
              {t('app.payment.top-up.fee')} {fee.euro} EUR
            </Text>
          </View>
        ) : null}
      </Dialog.ScrollArea>
    </View>
  );
}

function SendDialogContent({
  visible,
  getImageUrl,
  showMessage,
}: {
  visible: boolean;
  getImageUrl: BlockData['getImageUrl'];
  showMessage: (next: string) => void;
}) {
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const { data: groups, isLoading } = useGroupsDetached({ enabled: visible });
  const [amount, setAmount] = useState('2.50');
  const [nextRecipient, setNextRecipient] = useState('');

  const width = useWindowWidth();
  const people = useMemo(
    () =>
      groups
        ? groups
            .flatMap((group) => group.users)
            .filter(
              (user, index, self) =>
                self.findIndex((item) => item._id === user._id) === index
            )
            .sort(
              (a, b) =>
                (a.name.full || '').localeCompare(b.name.full || '') ||
                a._id.localeCompare(b._id)
            )
        : [],
    [groups]
  );

  const { mutateAsync: startTransfer, isLoading: isSending } = useMutation<
    unknown,
    FetchMediaError,
    {
      amount: number;
      recipientId: string;
    }
  >(
    ['app', 'payment', 'transfer'],
    async ({ amount, recipientId }) => {
      await queryClient.cancelQueries([authorization, 'app', 'balance']);

      const result = await transferMoney(endpoint, authorization, {
        recipientId,
        cents: amount,
      });

      return result;
    },
    {
      onSettled: async () => {
        return queryClient.invalidateQueries([authorization, 'app', 'balance']);
      },

      onSuccess: (_, variables) => {
        setNextRecipient('');
        showMessage(
          t('app.payment.transfer.success', {
            amount: (variables.amount / 100).toFixed(2),
          })
        );
      },

      onError: (error, variables) => {
        showMessage(
          t('app.payment.transfer.error', {
            amount: (variables.amount / 100).toFixed(2),
            error: error.message,
          })
        );
      },
    }
  );

  if (isLoading || isSending) {
    return (
      <Fragment>
        <Dialog.Title>{t('app.payment.transfer.title')}</Dialog.Title>
        <View style={{ justifyContent: 'center', minHeight: 200 }}>
          <ActivityIndicator />
        </View>
      </Fragment>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <Dialog.Title>{t('app.payment.transfer.title')}</Dialog.Title>

      <View style={{ position: 'relative', marginTop: -6, marginBottom: 6 }}>
        <TextInput
          keyboardType="decimal-pad"
          onChangeText={setAmount}
          value={amount}
          mode="outlined"
          label={t('app.payment.transfer.label')}
          style={{ marginHorizontal: 12 }}
        />
        <IconButton
          style={{ zIndex: 1, right: 12, top: 11, position: 'absolute' }}
          icon="plus-box"
          onPress={() => setAmount((parseFloat(amount) + 2.5).toFixed(2))}
          disabled={isNaN(parseFloat(amount))}
        />
      </View>

      <Dialog.ScrollArea style={{ paddingHorizontal: 0, maxHeight: 300 }}>
        <ScrollView
          contentContainerStyle={{
            margin: 0,
            paddingHorizontal: 0,
            paddingVertical: 16,
          }}
        >
          {people.map((person) => (
            <List.Item
              key={person._id}
              left={() => <ChatAvatar info={person} />}
              style={{ paddingStart: 16 }}
              title={
                person.name.full ||
                [person.name.first, person.name.last].filter(Boolean).join(' ')
              }
              description={
                nextRecipient === person._id
                  ? t('app.payment.transfer.confirm', { amount })
                  : ' '
              }
              onPress={
                amount && parseFloat(amount) >= 0
                  ? () => setNextRecipient(person._id)
                  : undefined
              }
              right={
                nextRecipient === person._id
                  ? () =>
                      isSending ? (
                        <ActivityIndicator size="small" />
                      ) : (
                        <IconButton
                          icon="send"
                          accessibilityLabel={t('app.payment.actions.transfer')}
                          disabled={isNaN(parseFloat(amount)) || isSending}
                          onPress={() =>
                            startTransfer({
                              amount: Number(
                                (parseFloat(amount) * 100).toFixed(0)
                              ),
                              recipientId: person._id,
                            })
                          }
                        />
                      )
                  : undefined
              }
            />
          ))}
        </ScrollView>
      </Dialog.ScrollArea>
    </View>
  );
}

function ChatAvatar({ info }: { info: TactileChatInfo | undefined }) {
  const image = useChatImage(info, 'icon_64');
  const initials = useChatInitials(info);
  const {
    colors: { primary, surface },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{ marginRight: 8, backgroundColor: surface }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginRight: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}
