import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { PaymentScreen } from './PaymentScreen';

mergeTranslations({
  en: {
    app: {
      payment: {
        title: 'Your balance',
        'top-up': {
          title: 'Top-up',
          label: 'Select an amount',
          change: 'Different method',
          fee: 'Service costs',
        },
        transfer: {
          title: 'Transfer (send)',
          label: 'Amount to send',
          confirm: 'Press the send button to transfer {{amount}} EUR',
          success: 'Transferred {{amount}} EUR',
          error: 'Did not transfer {{amount}} EUR: {{error}}',
        },
        transactions: {
          title: 'Transactions',
        },
        actions: {
          'top-up': 'Top-up',
          transfer: 'Send',
          negative_action: 'Cancel',
          transactions: 'More transaction history',
        },
      },
    },
  },

  nl: {
    app: {
      payment: {
        title: 'Jouw balans',
        'top-up': {
          title: 'Bijvullen',
          label: 'Kies een waarde',
          change: 'Andere manier',
          fee: 'Service kosten',
        },
        transfer: {
          title: 'Overdragen (geef)',
          label: 'Hoeveelheid om te geven',
          confirm: 'Klik nogmaals om {{amount}} EUR over te dragen',
          success: 'Er is {{amount}} EUR overgedragen',
          error: '{{amount}} EUR is niet overgedragen: {{error}}',
        },
        transactions: {
          title: 'Transacties',
        },
        actions: {
          'top-up': 'Bijvullen',
          transfer: 'Draag over',
          negative_action: 'Annuleer',
          transactions: 'Bekijk meer',
        },
      },
    },
  },
});

export function PaymentTab() {
  return <PaymentScreen asTab />;
}
