import Constants from 'expo-constants';
import { UrlMissing } from './errors/UrlMissing';

const manifest = Constants.manifest || { extra: {} };
const extra = manifest.extra || {};

const DOMAIN_TACTILE = extra['tactile-domain'];
const DOMAIN_INTROCLOUD = extra['introcloud-domain'];

export const NATIVE_DOMAIN = DOMAIN_TACTILE || DOMAIN_INTROCLOUD;

export async function openExternalUrl(url: string | null | undefined) {
  const url_ = url && url.trim();

  if (!url_) {
    throw new UrlMissing();
  }

  const finalUrl = url_?.includes(':') ? url_ : `https://${url_}`;

  const anchor = document.createElement('a');
  anchor.setAttribute('rel', 'noreferrer noopener');
  anchor.setAttribute('href', finalUrl);
  anchor.setAttribute('target', '__blank');
  anchor.click();
}
