import {
  mergeTranslations,
  PrimaryButton,
  useLocalization,
} from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { usePageData } from '@introcloud/page';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { FetchMediaError, JsonError } from 'fetch-media';
import { t } from 'i18n-js';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import {
  ActivityIndicator,
  HelperText,
  ThemeProvider,
  useTheme,
} from 'react-native-paper';
import { logout } from '../core/Authentication';
import { BlockProvision } from '../core/BlockProvision';
import {
  FallbackRoute,
  Header,
  HeaderProps,
  ValidRoutes,
} from '../core/Header';
import { PAGE_COLORS_ENABLED } from '../features';
import { useCompany } from '../hooks/useCompany';
import { usePage } from '../hooks/usePage';
import { PageViewer } from './PageViewer';

export function BlockProvidedPage<T extends ValidRoutes>({
  page,
  hideBack,
  fallBack,
  colorOverride,
  screen,
  showLogo,
}: PageWithContextProps<T> & {
  page: string | null;
  screen?: string;
  showLogo?: boolean;
}) {
  if (!page) {
    return <NotFound />;
  }

  return (
    <Page
      page={page}
      hideBack={hideBack}
      fallBack={fallBack}
      colorOverride={colorOverride}
      screen={screen}
      showLogo={showLogo}
    />
  );
}

export function Page<T extends ValidRoutes>({
  page,
  hideBack,
  fallBack,
  colorOverride,
  screen = 'Page',
  showLogo,
}: PageWithContextProps<T> & {
  page: string;
  screen?: string;
  showLogo?: boolean;
}) {
  const enabled = useIsFocused();

  return (
    <BlockProvision screen={screen} page={page} enabled={enabled}>
      <GuardForPageErrors
        page={page}
        enabled={enabled}
        hideBack={hideBack}
        fallBack={fallBack}
        colorOverride={colorOverride}
      >
        <PageWithContext
          hideBack={hideBack}
          fallBack={fallBack}
          colorOverride={colorOverride}
          showLogo={showLogo}
        />
      </GuardForPageErrors>
    </BlockProvision>
  );
}

function GuardForPageErrors<T extends ValidRoutes>({
  page: pageId,
  enabled,
  children,
  hideBack,
  fallBack,
  colorOverride,
}: React.PropsWithChildren<{
  page: string;
  enabled: boolean;
  hideBack?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
}>) {
  const { getInfoById } = useBlockData();

  const { error: pageError } = usePage(pageId, getInfoById, {
    enabled,
  });

  if (
    pageError instanceof FetchMediaError &&
    [403, 404, 409, 422].includes(pageError.response.status)
  ) {
    if (pageError instanceof JsonError) {
      if (isApplicationClosedError(pageError)) {
        return (
          <ApplicationClosedError<T>
            hideBack={hideBack}
            backFallback={fallBack}
            colorOverride={colorOverride}
          />
        );
      }
      return (
        <View style={{ flex: 1, overflow: 'hidden' }}>
          <Header
            title={' '}
            subTitle={undefined}
            hideBack={hideBack}
            backFallback={fallBack}
            colorOverride={colorOverride}
            showTranslate
          />

          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HelperText type="error">
              That content is not available or does not exist. (
              {'decline' in pageError.data
                ? (pageError.data.decline[0] ?? pageError).message
                : pageError.message}
              )
            </HelperText>
          </View>
        </View>
      );
    }

    return (
      <HelperText type="error">
        That content is not available or does not exist. ({pageError.message})
      </HelperText>
    );
  }

  return <Fragment>{children}</Fragment>;
}

mergeTranslations({
  en: {
    app: {
      application_closed: {
        title: 'Application closed',
        description: 'The application is currently closed. Check back later!',
        logout: 'Logout',
      },
      not_found: {
        description: 'That content is not available or does not exist.',
      },
    },
  },
  nl: {
    app: {
      application_closed: {
        title: 'Applicatie gesloten',
        description:
          'De applicatie is momenteel gesloten. Probeer het later nog eens!',
        logout: 'Uitloggen',
      },
      not_found: {
        description: 'Die content is niet beschikbaar of bestaat niet.',
      },
    },
  },
});

function ApplicationClosedError<T extends ValidRoutes>({
  hideBack,
  backFallback,
  colorOverride,
}: Pick<HeaderProps<T>, 'hideBack' | 'backFallback' | 'colorOverride'>) {
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Header
        title={t('app.application_closed.title')}
        subTitle={undefined}
        hideBack={hideBack}
        backFallback={backFallback}
        colorOverride={colorOverride}
        showTranslate
      />

      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <HelperText type="error" style={{ marginBottom: 8 }}>
          {t('app.application_closed.description')}
        </HelperText>

        <PrimaryButton onPress={() => logout()}>
          {t('app.application_closed.logout')}
        </PrimaryButton>
      </View>
    </View>
  );
}

function isApplicationClosedError(error: JsonError) {
  return (
    'error' in error.data && error.data.error === 'you-are-not-application'
  );
}

export interface PageWithContextProps<T extends ValidRoutes> {
  hideBack?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
  showLogo?: boolean;
}

export function PageWithContext<T extends ValidRoutes>({
  hideBack,
  fallBack,
  colorOverride,
  showLogo,
}: PageWithContextProps<T>) {
  const { page } = usePageData();

  const company = useCompany();
  const { setOptions } = useNavigation();

  const finalName = useLocalization(page?.nameLocalized?.full, page?.name.full);

  const companyName = company?.name.full;

  useEffect(() => {
    if (finalName) {
      setOptions({
        title: `${finalName} · ${companyName ?? 'Tactile'}`,
      });
    }
  }, [page, finalName, companyName]);

  const hadToLoadRef = useRef(false);
  const theme = useTheme();
  const pageTheme = useMemo(() => {
    if (!PAGE_COLORS_ENABLED || !page?.module?.application?.colors) {
      return theme;
    }

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: page.module.application.colors.primary || theme.colors.primary,
        accent: page.module.application.colors.accent || theme.colors.accent,
      },
    };
  }, [theme, page]);

  if (!page) {
    hadToLoadRef.current = true;

    return (
      <LoadingPage
        hideBack={hideBack}
        fallBack={fallBack}
        colorOverride={colorOverride}
      />
    );
  }

  return (
    <ThemeProvider theme={pageTheme}>
      <PageViewer
        hideBack={hideBack}
        hadToLoad={hadToLoadRef.current}
        fallBack={fallBack}
        colorOverride={colorOverride}
        showLogo={showLogo}
      />
    </ThemeProvider>
  );
}

export function LoadingPage<T extends ValidRoutes>({
  hideBack,
  fallBack,
  colorOverride,
}: {
  hideBack?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
}) {
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Header
        title={' '}
        subTitle={undefined}
        hideBack={hideBack}
        backFallback={fallBack}
        colorOverride={colorOverride}
        showTranslate
      />

      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    </View>
  );
}

export function NotFound() {
  return (
    <HelperText type="error">
      {t('app.application.not_found.description')}
    </HelperText>
  );
}
