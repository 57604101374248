import { useDimensions } from '@introcloud/blocks-interface';
import { BlockProps, VideoEmbedBlockOptions } from '@introcloud/page';
import { prepare } from '@introcloud/page/dist/blocks/VideoEmbedBlock';
import React from 'react';
import { PixelRatio, Platform, StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { WebView } from 'react-native-webview';

export function YouTubeVideoEmbedBlock(
  props: BlockProps<VideoEmbedBlockOptions>
) {
  const videoId = props.value.src?.replace(
    'https://www.youtube-nocookie.com/embed/',
    ''
  );
  const { roundness } = useTheme();
  const { width } = useDimensions();
  const {
    colors: { primary, placeholder },
  } = useTheme();

  const uri = prepare(
    props.value.src,
    props.value.type,
    primary,
    Platform.select({
      web: false,
      android: false,
      ios: true,
      default: false,
    }),
    props.options.autoplay
  );

  const round = { top: false, bottom: false };
  const ratio = props.options.ratio[0] / props.options.ratio[1];
  const maxWidth = 720;
  const allowFullScreen = true;
  const autoplay = props.options.autoplay;

  const actualWidth = Math.min(maxWidth, width);
  const height = PixelRatio.roundToNearestPixel(actualWidth / ratio);
  const topRadius = round?.top ? roundness : 0;
  const bottomRadius = round?.bottom ? roundness : 0;
  const borderStyle = {
    borderTopLeftRadius: topRadius,
    borderTopRightRadius: topRadius,
    borderBottomLeftRadius: bottomRadius,
    borderBottomRightRadius: bottomRadius,
    width: actualWidth,
    height,
  };

  return (
    <View style={[styles.wrapper, borderStyle]}>
      <WebView
        source={{
          html: `
<!DOCTYPE html>
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
  <body>
    <style>
      html, body {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;
      }
    </style>

    <!-- 1. The <iframe> (and video player) will replace this <div> tag. -->
    <iframe id="existing-iframe-example"
      width="${Math.round(actualWidth)}"
      height="${Math.round(height)}"
      src="${uri}"
      frameborder="0"
      ${allowFullScreen ? 'allowFullScreen' : ''}
      allow="camera;microphone;${autoplay ? 'autoplay' : ''};${
            allowFullScreen ? 'fullscreen;' : ''
          }"
    ></iframe>

    <script>
      // 2. This code loads the IFrame Player API code asynchronously.
      var tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // 3. This function creates an <iframe> (and YouTube player)
      //    after the API code downloads.
      var player;
      function onYouTubeIframeAPIReady() {
        player = new YT.Player('existing-iframe-example', {
         //  height: '${Math.round(height)}',
         //  width: '${Math.round(width)}',
         //  videoId: '${videoId}',
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
      }

      // 4. The API will call this function when the video player is ready.
      function onPlayerReady(event) {
        ${autoplay ? 'event.target.playVideo()' : ''}
      }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the player should play for six seconds and then stop.
      var done = false;
      function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
          //setTimeout(stopVideo, 6000);
          done = true;
        }
      }
      function stopVideo() {
        player.stopVideo();
      }

      document.addEventListener('DOMContentLoaded', onYouTubeIframeAPIReady);
    </script>
  </body>
</html>`.trim(),
        }}
        allowsFullscreenVideo={allowFullScreen}
        onShouldStartLoadWithRequest={(event) => {
          const { navigationType, url } = event;
          return (
            (navigationType === 'other' || navigationType === 'reload') &&
            (url === uri || url === 'about:blank')
          );
        }}
        sharedCookiesEnabled
        javaScriptEnabled
        mediaPlaybackRequiresUserAction={false}
        onNavigationStateChange={(event) => event.url}
        style={{
          ...borderStyle,
          backgroundColor: placeholder,
          overflow: 'hidden',
          opacity: Platform.OS === 'android' ? 0.99 : 1,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
    backgroundColor: '#222',
  },
});
