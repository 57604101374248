import NetInfo from '@react-native-community/netinfo';
import { FetchMediaError } from 'fetch-media';
import React from 'react';
import type { AppStateStatus } from 'react-native';
import { AppState } from 'react-native';
import {
  focusManager,
  onlineManager,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { NotFound } from '../errors/NotFound';
import { imperativeLogout } from '../hooks/useAuthentication';
import { NothingToFetch } from './errors/NothingToFetch';
import { NotReady } from './errors/NotReady';
import { MaybeDevTools } from './MaybeDevTools';

focusManager.setEventListener((onFocus) => {
  const handleAppStateChange = (appState: AppStateStatus) => {
    if (appState === 'active') {
      onFocus();
    }
  };

  AppState.addEventListener('change', handleAppStateChange);
  return () => AppState.removeEventListener('change', handleAppStateChange);
});

onlineManager.setEventListener((setOnline: (isOnline: boolean) => void) => {
  return NetInfo.addEventListener((state) => {
    state.isConnected !== null && setOnline(state.isConnected);
  });
});

type ClientErrorStatus =
  | 400 // Bad Request
  | 401 // Unauthorized
  | 402 // Payment Required
  | 403 // Forbidden
  | 404 // Not Found
  | 405 // Method Not Allowed
  | 406 // Not Acceptable
  | 407 // Proxy Authentication Required
  | 408 // Request Timeout
  | 409 // Conflict
  | 410 // Gone
  | 411 // Length Required
  | 412 // Precondition Failed
  | 413 // Payload Too Large
  | 414 // Request-URI Too Long
  | 415 // Unsupported Media Type
  | 416 // Requested Range Not Satisfiable
  | 417 // Expectation Failed
  | 418 // I'm a teapot
  | 421 // Misdirected Request
  | 422 // Unprocessable Entity
  | 423 // Locked
  | 424 // Failed Dependency
  | 426 // Upgrade Required
  | 428 // Precondition Required
  | 429 // Too Many Requests
  | 431 // Request Header Fields Too Large
  | 444 // Connection Closed Without Response
  | 451 // Unavailable For Legal Reasons
  | 499; // Client Closed Request
function isClientError(
  error: null | Error
): error is FetchMediaError & { response: { status: ClientErrorStatus } } {
  console.warn({
    error,
    type: error instanceof FetchMediaError,
    status: error instanceof FetchMediaError ? error.response.status : -1,
  });
  return (
    error instanceof FetchMediaError &&
    error.response.status >= 400 &&
    error.response.status < 500
  );
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async (error) => {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            await imperativeLogout();
          }
        }
      },

      retry: (failureCount, error) => {
        if (error instanceof NotFound) {
          return false;
        }

        if (error instanceof NothingToFetch) {
          return false;
        }

        if (error instanceof NotReady) {
          return false;
        }

        // When the error is a client error, there is no need to retry the
        // query. In this case, retrying won't solve the initial issue (unless
        // the server is somehow misbehaving).
        if (error instanceof Error && isClientError(error)) {
          if (error.response.status === 429) {
            return true;
          }

          return false;
        }

        // At most retry {retryCount} times. By default it will use exponential
        // backing to retry the query.
        if (failureCount >= 3) {
          return false;
        }

        return true;
      },

      retryDelay: (failureCount, error) => {
        if (error instanceof Error && isClientError(error)) {
          if (error.response.status === 429) {
            const when = error.response.headers.get('Retry-After');
            if (when) {
              return Math.max(
                1000,
                new Date(when).getTime() - new Date().getTime()
              );
            }
          }
        }

        return Math.min(1000 * 2 ** failureCount, 30000);
      },
    },
  },
});

export function QueryCacheProvider({
  children,
}: React.PropsWithChildren<object>) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <MaybeDevTools />
    </QueryClientProvider>
  );
}
