import { mergeTranslations } from '@introcloud/blocks';
import React from 'react';

import { useTabColor } from '../hooks/useTabColor';
import { GoalsScreen } from './GoalsScreen';

mergeTranslations({
  en: {
    app: {
      goals: {
        actions: {
          submit: 'Submit',
          close: 'Close',
          more: 'More...',
          select_one: 'Select an answer',
        },
        labels: {
          picture: 'Select an image',
          video: 'Select a video',
        },
        goal: {
          future: {
            description: 'This goal can not be submitted yet. Come back later!',
          },
          past: {
            description: 'This goal can no longer be submitted. Sorry!',
          },
          manual: {
            description:
              "You can't submit an answer. Someone needs to award you this item.",
          },
          radio: {
            title: 'Select one',
          },
          text: {
            label: 'Answer',
          },
        },
      },
    },
  },

  nl: {
    app: {
      goals: {
        actions: {
          submit: 'Verstuur',
          close: 'Sluit',
          more: 'Meer...',
          select_one: 'Kies een antwoord',
        },
        labels: {
          picture: 'Selecteer een plaatje',
          video: 'Selecteer een video',
        },
        goal: {
          future: {
            description:
              'Deze opdracht kan nog niet worden vervuld. Probeer het later nog eens!',
          },
          past: {
            description: 'Deze opdracht kan niet meer worden vervuld. Sorry!',
          },
          manual: {
            description:
              'Deze opdracht kan je niet vervullen door iets in te sturen. Iemand zal je punten moeten toekennen.',
          },
          radio: {
            title: 'Selecteer één',
          },
          text: {
            label: 'Antwoord',
          },
        },
      },
    },
  },
});

export function GoalsTab() {
  const color = useTabColor('goals');

  return <GoalsScreen asTab colorOverride={color} />;
}
