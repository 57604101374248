import { useLocalization } from '@introcloud/blocks';
import { TabIdentifier } from '@introcloud/tabs';

import { useTab } from './useTab';

export function useTabTitle(identifier: TabIdentifier, fallback: string) {
  const tab = useTab(identifier);
  const title = useLocalization(
    tab?.titleLocalized || tab?.localizedTitle,
    tab?.title
  );

  return title || tab?.title || fallback;
}
