import { CURRENT_LOCALE } from '@introcloud/blocks';
import {
  BlockNavigation,
  ProvideBlockData,
  ProvideBlockNavigation,
  useBlockNavigation,
} from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { VideoStreamBlockOptions } from '@introcloud/page';
import { createURL, openURL } from 'expo-linking';
import I18n from 'i18n-js';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { Appbar, TextInput, ThemeProvider, useTheme } from 'react-native-paper';
import Animated, { add } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Analytics } from '../analytics';
import { ChatMessages, usePublishChat } from '../chats/ChatScreen';
import { ChatResolver } from '../chats/ResolveChatScreen';
import { linkTo as gotoInternal } from '../core/RootNavigation';
import { useEndpoint } from '../hooks/useAuthentication';
import { openExternalUrl } from '../open';
import { PlayerVideo } from './PlayerContext';

type VideoContentProps = {
  video: PlayerVideo;
  chat: VideoStreamBlockOptions['value']['chat'] | undefined;
  onDismiss: () => void;
  keyboardAvoidingHeight: Animated.Node<number>;
};

export class VideoContent extends React.PureComponent<VideoContentProps> {
  render() {
    const { video, onDismiss, chat, keyboardAvoidingHeight } = this.props;
    const locale = CURRENT_LOCALE.current || I18n.defaultLocale;

    const localizedName = localize(
      video.nameLocalized?.full,
      video.name?.full,
      locale
    );
    const localizedPageName = localize(
      video.page?.nameLocalized?.full,
      video.page?.name.full,
      locale
    );

    return (
      <Fragment>
        <Appbar
          style={{
            maxWidth: 720,
            backgroundColor: 'transparent',
            elevation: 0,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <Appbar.Content
            color="#fff"
            title={localizedName || localizedPageName || 'Loading...'}
          />
          <Appbar.Action color="#fff" icon="close" onPress={onDismiss} />
        </Appbar>
        {chat !== undefined ? (
          <Chat
            key={video.pageId}
            chat={chat}
            pageId={video.pageId}
            keyboardAvoidingHeight={keyboardAvoidingHeight}
          />
        ) : null}
      </Fragment>
    );
  }
}

function Chat({
  chat,
  pageId,
  keyboardAvoidingHeight,
}: {
  chat: VideoStreamBlockOptions['value']['chat'] | undefined;
  pageId: string;
  keyboardAvoidingHeight: Animated.Node<number>;
}) {
  const theme = useTheme();
  const [conversationId, setConversationId] = useState<string | undefined>();
  const navigation = useBlockNavigation();
  // const linkTo = useLinkTo();
  const endpoint = useEndpoint();
  const provider = useMemo(() => {
    const getImageUrl = (
      imageId: string,
      targetSize:
        | 'icon_32'
        | 'icon_64'
        | 'icon_128'
        | 'icon_256'
        | 'icon_512'
        | 'icon_720'
        | 'icon_1440'
    ) => {
      if (!imageId || imageId.trim().length === 0) {
        return null;
      }

      return endpoint + `/image/${imageId}/${targetSize}`;
    };

    return { getImageUrl };
  }, [endpoint]);

  const gotoChat = useCallback(
    (next: string) => {
      setConversationId(next);
    },
    [setConversationId]
  );

  const next: BlockNavigation = useMemo(
    () => ({
      ...navigation,
      gotoChat,
      gotoExternal: (url: string) => {
        Analytics.logEvent('gotoExternal', {
          url,
          source: { page: { id: pageId } },
        });

        openExternalUrl(url, theme.colors.primary).catch((err) => {
          /* swallow error */
          console.error(err);
        });
      },
      gotoInternal(path: string) {
        path = path[0] === '/' ? path.slice(1) : path;

        try {
          gotoInternal(`/${path}`) || openURL(createURL(`/${path}`));
        } catch (_) {
          openURL(createURL(`/${path}`));
        }

        // navigationRef.current?.dispatch(action);
      },
    }),
    [navigation, gotoChat, pageId, theme.colors.primary]
  );

  if (conversationId) {
    return (
      <ProvideBlockNavigation navigation={next}>
        <ProvideBlockData provider={provider}>
          <ResolvedChat
            conversationId={conversationId}
            keyboardAvoidingHeight={keyboardAvoidingHeight}
          />
        </ProvideBlockData>
      </ProvideBlockNavigation>
    );
  }

  if (!chat) {
    return null;
  }

  return (
    <View style={{ flex: 1, width: '100%' }}>
      <ProvideBlockNavigation navigation={next}>
        <ThemeProvider theme={{ ...theme, mode: 'adaptive' }}>
          <View style={{ height: '100%', width: '100%' }}>
            <ChatResolver
              hasGroupScope={chat === 'group'}
              hasPageScope
              hasUserScope={chat === '1-on-1'}
              page={pageId}
              hideHeader
            />
          </View>
        </ThemeProvider>
      </ProvideBlockNavigation>
    </View>
  );
}

function ResolvedChat({
  conversationId,
  keyboardAvoidingHeight,
}: {
  conversationId: string;
  keyboardAvoidingHeight: Animated.Node<number>;
}) {
  const { bottom } = useSafeAreaInsets();

  return (
    <View style={{ flex: 1, width: '100%' }}>
      <View
        style={{
          position: 'relative',
          flex: 1,
          width: '100%',
          zIndex: 2,
        }}
      >
        <ChatMessages
          channel={conversationId}
          hasOverlayInput={Platform.select({
            android: 64,
            default: 64,
            ios: 0,
          })}
          wideReminders
          padWhenKeyboardIsActive={false}
        />

        <ChatInput channel={conversationId} />
      </View>
      {Platform.OS === 'ios' ? (
        <Animated.View
          style={{
            height: add(keyboardAvoidingHeight, 64),
            position: 'relative',
            zIndex: 1,
          }}
        />
      ) : null}
    </View>
  );
}

//

export function ChatInput({ channel }: { channel: string }) {
  const publish = usePublishChat(channel, false);
  const [input, setInput] = useState('');
  const { bottom } = useSafeAreaInsets();

  return (
    <TextInput
      style={{
        position: 'absolute',
        bottom: 0,
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
        marginBottom: Platform.OS === 'ios' ? -64 : 0,
        // height: Platform.select({ android: /*80*/, default: undefined }),
        paddingRight: Platform.OS === 'ios' ? 12 : 52,
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, .1)',
      }}
      returnKeyType="send"
      placeholder="Say something..."
      value={input}
      onSubmitEditing={(e) => {
        const text = e.nativeEvent.text;
        if (text && text.trim()) {
          publish(text);
          setInput('');
        }
      }}
      onChangeText={setInput}
      enablesReturnKeyAutomatically
      autoCapitalize="sentences"
      autoCorrect
      blurOnSubmit={Platform.OS === 'ios'}
      clearButtonMode="while-editing"
      right={
        <TextInput.Icon
          color="white"
          disabled={!input || !input.trim()}
          name="send"
          onPress={() => {
            publish(input.trim());
            setInput('');
          }}
        />
      }
    />
  );
}
