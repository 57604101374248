import { TactileLocation } from '@introcloud/api-client';
import {
  locationTagIconFor,
  useLocale,
  useLocalization,
} from '@introcloud/blocks';
import { BlockData, useBlockData } from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  Polygon,
  Polyline,
  useGoogleMap,
} from '@react-google-maps/api';
import Constants from 'expo-constants';
import React, { useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { useCompany } from '../hooks/useCompany';
import { DEFAULT_ICON, ICONS } from './Icons';

const key = Constants.manifest?.extra
  ? Constants.manifest.extra['google-maps-key']
  : undefined;
const OPTIONS = { disableDefaultUI: true };

function MapView_({
  center,
  locations,
  selected,
  onSelect,
  filter,
}: {
  center: { latitude: number; longitude: number };
  locations: readonly TactileLocation[];
  selected: TactileLocation | null;
  onSelect(location: TactileLocation | null): void;
  filter: string | null;
}) {
  const { getImageUrl } = useBlockData();
  const center_ = useMemo(
    () => ({
      lat: center.latitude,
      lng: center.longitude,
    }),
    [center.latitude, center.longitude]
  );
  const locale = useLocale();

  return (
    <LoadScriptNext googleMapsApiKey={key}>
      <GoogleMap
        clickableIcons={false}
        mapContainerStyle={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        center={center_}
        zoom={13}
        options={{ ...OPTIONS, ...useMapOptions() }}
      >
        <PanToSelected selected={selected} />
        {(locations || [])
          .filter((location) => {
            const tags =
              localize(
                location.nameLocalized?.tag,
                location.name.tag,
                locale
              ) || [];

            return (
              filter === null ||
              location._id === selected?._id ||
              tags.includes(filter) ||
              (filter === '' && tags.length === 0)
            );
          })
          .map((location) => (
            <LocationMarker
              location={location}
              key={location._id}
              onSelect={onSelect}
              getImageUrl={getImageUrl}
            />
          ))}
      </GoogleMap>
    </LoadScriptNext>
  );
}

function useMapOptions(): google.maps.MapOptions {
  const company = useCompany();
  const isEnabled = ['demo', 'demo-app', 'tactilefair'].includes(
    company?.name.id || ''
  );

  if (Platform.OS === 'ios' || !isEnabled) {
    return {};
  }

  return {
    styles: [
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.government',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.medical',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.place_of_worship',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.school',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.sports_complex',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#d9dce0',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#bcc1c6',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };
}

function PanToSelected({ selected }: { selected: TactileLocation | null }) {
  const map = useGoogleMap();
  useEffect(() => {
    const [latitude, longitude] = selected?.geojson?.coordinates || [];
    if (latitude && longitude && map && !Array.isArray(latitude)) {
      try {
        // eslint-disable-next-line no-unused-expressions
        map.panTo({
          lat: Number(latitude),
          lng: Number(longitude),
        });
      } catch {}
    }
  }, [selected, map]);

  return null;
}

const remoteSize = Platform.select({
  web: 48,
  ios: 48,
  default: 48,
  android: 72,
});

function LocationMarker_({
  location,
  onSelect,
  getImageUrl,
}: {
  location: TactileLocation;
  onSelect(location: TactileLocation | null): void;
  getImageUrl: BlockData['getImageUrl'];
}) {
  const localizedTags =
    useLocalization(location.nameLocalized?.tag, location.name.tag) || [];

  const { geojson, image } = location;
  const icon =
    localizedTags.map((t) => {
      const tagIcon = locationTagIconFor(t);
      if (!tagIcon) {
        return false;
      }
      return ICONS[tagIcon as keyof typeof ICONS];
    })[0] || DEFAULT_ICON;

  const remoteIcon = getImageUrl(
    image?.mapIcon || '',
    `icon_${remoteSize}` as any
  );
  const actualIcon = remoteIcon ? remoteIcon : icon;

  switch (geojson?.type) {
    case 'Point': {
      if (!geojson.coordinates || geojson.coordinates.length < 2) {
        return null;
      }

      return (
        <Marker
          icon={actualIcon}
          position={{
            lat: Number(geojson.coordinates[0]),
            lng: Number(geojson.coordinates[1]),
          }}
          onClick={() => onSelect(location)}
        />
      );
    }
    case 'LineString': {
      if (!geojson.coordinates || geojson.coordinates.length <= 1) {
        return null;
      }

      const validPoints = geojson.coordinates.filter(
        (point) =>
          point.length >= 2 &&
          !isNaN(Number(point[0])) &&
          !isNaN(Number(point[1]))
      );

      if (!validPoints || validPoints.length <= 1) {
        return null;
      }

      return (
        <Polyline
          path={validPoints.map((point) => ({
            lat: Number(point[0]),
            lng: Number(point[1]),
          }))}
          onClick={() => onSelect(location)}
        />
      );
    }
    case 'Polygon': {
      if (!geojson.coordinates || geojson.coordinates.length <= 1) {
        return null;
      }

      const validPoints = geojson.coordinates.flatMap((points) =>
        points.filter(
          (point) =>
            point.length >= 2 &&
            !isNaN(Number(point[0])) &&
            !isNaN(Number(point[1]))
        )
      );

      if (!validPoints || validPoints.length <= 1) {
        return null;
      }

      return (
        <Polygon
          paths={validPoints.map((point) => ({
            lat: Number(point[0]),
            lng: Number(point[1]),
          }))}
          onClick={() => onSelect(location)}
        />
      );
    }
    default: {
      return null;
    }
  }
}

const LocationMarker = React.memo(LocationMarker_);
export const MapView = React.memo(MapView_);
