import { PrimaryButton, useLocale } from '@introcloud/blocks';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { t } from 'i18n-js';
import { View } from 'react-native';
import { RouteParamList } from '../core/Routes';
import { useCompany } from '../hooks/useCompany';
import { useTab } from '../hooks/useTab';

export function ProfileActions() {
  const company = useCompany();
  // const chatDisabled = !company?.settings.pubnub.active;

  return (
    <View style={{ flexDirection: 'row' }}>
      {company?.settings.profileShowChat ? (
        <View style={{ marginRight: 4 }}>
          <ChatButton />
        </View>
      ) : null}
      {company?.settings.profileShowPayment ? <PaymentButton /> : null}
    </View>
  );
}

function PaymentButton() {
  useLocale();

  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const tab = useTab('payment');

  return (
    <PrimaryButton
      style={{
        marginTop: 8,
        marginRight: 'auto',
        marginBottom: 8,
      }}
      icon={
        ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
          (tab?.icon.name as 'account-cash-outline')) ||
        'account-cash-outline'
      }
      onPress={() => navigate('Payment')}
    >
      {t('app.actions.goto_balance')}
    </PrimaryButton>
  );
}

function ChatButton({ disabled = false }: { disabled?: boolean }) {
  useLocale();

  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const tab = useTab('chats');

  return (
    <PrimaryButton
      style={{
        marginTop: 8,
        marginRight: 'auto',
        marginLeft: 16,
        marginBottom: 8,
      }}
      icon={
        ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
          (tab?.icon.name as 'forum')) ||
        'chat'
      }
      onPress={() => navigate('Chats')}
      disabled={disabled}
    >
      {t('app.actions.goto_chats')}
    </PrimaryButton>
  );
}
