import { TactileGameMap } from '@introcloud/api-client';
import { useLocalization } from '@introcloud/blocks';
import { useBlockDataGameMap } from '@introcloud/blocks-interface';
import { PageData } from '@introcloud/page';
import { KIND_GAME_MAP } from '@introcloud/page/dist/Kinds';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useGroups } from '../hooks/useGroup';
import { useTabConfiguration } from '../hooks/useTab';
import { useUser } from '../hooks/useUser';
import { PageWithContext } from '../page/Page';

const EMPTY_TEXT = {
  en: `
  Walk around and interact with others.
  `
    .trim()
    .replace(/\n/g, ''),
  nl: `
  Loop rond en communiceer met anderen.
  `
    .trim()
    .replace(/\n/g, ''),
};

export function GameMapTab() {
  const configuration = useTabConfiguration('game-map');

  if (!configuration || !configuration.mapRef || !configuration.mapRef.mapId) {
    return <EmptyGameMapState />;
  }

  return (
    <BlockProvision screen="GameMapTab">
      <GameMapIdScreen id={configuration.mapRef.mapId} hideBack />
    </BlockProvision>
  );
}

function EmptyGameMapState() {
  const { values } = useCompanyTabs();
  const tab = useMemo(
    () => values.find((tab) => tab.tab === 'game-map'),
    [values]
  );
  const icon = useMemo(
    () => (tab ? tab.icon.name : 'gamepad-circle-up'),
    [tab]
  );
  const title = useLocalization(
    tab?.titleLocalized || tab?.localizedTitle,
    tab?.title
  );
  return (
    <EmptyState
      icon={icon}
      title={title || 'Game Map'}
      texts={EMPTY_TEXT}
      hidden={false}
    />
  );
}

function GameMapIdScreen({ id, hideBack }: { id: string; hideBack?: boolean }) {
  const { data: gameMap, loading, error } = useBlockDataGameMap(id);

  if (!gameMap) {
    // TODO loading/error
    return <EmptyGameMapState />;
  }

  return <GameMapAsPage gameMap={gameMap} hideBack />;
}

function GameMapAsPage({
  gameMap,
  hideBack,
}: {
  gameMap: TactileGameMap;
  hideBack?: boolean;
}) {
  const { data: currentUser } = useUser();
  const { data: currentGroups } = useGroups();

  const page = useMemo((): PageData => {
    return {
      page: {
        _id: gameMap._id,
        _v: -1,
        module: { application: { colors: { primary: '', accent: '' } } },
        name: { ...gameMap.name, id: '' },
        nameLocalized: gameMap.nameLocalized,
        subTitle: '',
        locationRef: [],
        eventRef: [],
        content: [
          {
            _id: uuid(),
            kind: KIND_GAME_MAP,
            value: {
              id: gameMap._id,
            },
            options: {},
          },
        ],
      },
      pageLocation: null,
      pageEvent: null,
      currentUser: currentUser || null,
      currentGroups: currentGroups || null,
    };
  }, [gameMap._id, currentUser]);

  return (
    <BlockProvision screen="GameMapAsPage (GameMapScreen)" page={page}>
      <PageWithContext hideBack={hideBack} />
    </BlockProvision>
  );
}
