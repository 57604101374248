import { TactileGroup } from '@introcloud/api-client';
import {
  AccentButton,
  ActionGrid,
  IconGridItemProps,
  Spacer,
  TextButton,
  useLocale,
} from '@introcloud/blocks';
import {
  ProvideBlockData,
  ProvideBlockNavigation,
  useBlockData,
  useBlockNavigation,
} from '@introcloud/blocks-interface';
import { useIsFocused } from '@react-navigation/native';
import Color from 'color';
import { t } from 'i18n-js';
import { Fragment, useMemo, useState } from 'react';
import { ScrollView } from 'react-native';
import { Dialog, Portal, useTheme } from 'react-native-paper';
import { useCompany } from '../hooks/useCompany';
import { useGroups } from '../hooks/useGroup';
import { Group } from './Group';

export function ProfileGroups() {
  const enabled = useIsFocused();
  const { data: groups } = useGroups({ enabled });

  if (groups === undefined) {
    return null;
  }

  if (groups === null || groups.length === 0) {
    return null;
  }

  if (groups.length === 1) {
    return <Group {...groups[0]} />;
  }

  return <Groups />;
}

function Groups() {
  useLocale();

  const company = useCompany();
  const showChat = company?.settings.profileShowChat;

  const enabled = useIsFocused();
  const { data: groups } = useGroups({ enabled });
  const {
    dark,
    colors: { surface },
  } = useTheme();
  const { getImageUrl } = useBlockData();
  const navigation = useBlockNavigation();

  const [selectedGroup, setSelectedGroup] = useState<
    TactileGroup | undefined
  >();
  const groupActions = useMemo((): readonly IconGridItemProps[] => {
    if (!groups) {
      return [];
    }

    return groups.map((group) => {
      const action: IconGridItemProps = {
        icon: 'account-group',
        label: group.name.full,
        destination: {
          kind: 'emit',
          value: group._id,
        },
      };

      return action;
    });
  }, [groups]);

  return (
    <Fragment>
      <ProvideBlockNavigation
        navigation={{
          emit(value) {
            setSelectedGroup(groups?.find((group) => group._id === value));
          },
        }}
      >
        <Spacer space={2} />
        <ActionGrid mode="icons" size="large" actions={groupActions} />
      </ProvideBlockNavigation>
      <Portal>
        <Dialog
          visible={Boolean(selectedGroup && enabled)}
          onDismiss={() => setSelectedGroup(undefined)}
          style={{
            alignSelf: 'center',
            maxWidth: 720,
            width: '100%',
            position: 'relative',
          }}
        >
          {showChat ? (
            <AccentButton
              icon="forum"
              style={{ position: 'absolute', right: 16, top: 14, zIndex: 1 }}
              labelStyle={{ fontSize: 16 }}
              onPress={() => {
                navigation.gotoChat(
                  `:group/${JSON.stringify({ group: selectedGroup?._id })}`
                );
                setSelectedGroup(undefined);
              }}
            >
              {t('app.actions.goto_group_chat')}
            </AccentButton>
          ) : null}
          <Dialog.Title style={{ position: 'relative' }}>
            {selectedGroup?.name.full ?? '-'}
          </Dialog.Title>
          <Dialog.ScrollArea
            style={{
              paddingHorizontal: 0,
              backgroundColor: dark
                ? undefined
                : new Color(surface).darken(0.05).string(),
            }}
          >
            <ProvideBlockNavigation navigation={navigation}>
              <ProvideBlockData provider={{ getImageUrl }}>
                <ScrollView style={{ maxHeight: 480, paddingTop: 16 }}>
                  {selectedGroup ? <Group {...selectedGroup} noHeader /> : null}
                </ScrollView>
              </ProvideBlockData>
            </ProvideBlockNavigation>
          </Dialog.ScrollArea>
          <Dialog.Actions
            style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
          >
            <TextButton
              icon="close"
              onPress={() => setSelectedGroup(undefined)}
            >
              {t('app.actions.close_dialog')}
            </TextButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}
