import { TactileNewsItem } from '@introcloud/api-client';
import {
  mergeTranslations,
  Spacer,
  TextBlock,
  useLocalization,
} from '@introcloud/blocks';
import { useBlockNavigation } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Card, List, useTheme } from 'react-native-paper';
import { localeDateString, localeTimeString } from 'react-native-time-helpers';
import { useMediaQuery } from 'react-responsive';
import { LinkButton } from '../core/LinkButton';
import { useNews } from '../hooks/useNews';

mergeTranslations({
  en: {
    app: {
      home: {
        last_news: {
          title: 'Latest news',
          older: 'See older',
        },
      },
    },
  },

  nl: {
    app: {
      home: {
        last_news: {
          title: 'Laatste nieuws',
          older: 'Archief',
        },
      },
    },
  },
});

export function LatestNewsItem() {
  const { data: news } = useNews();
  const otherNews = news?.filter((item) => item.kind !== 'pinned') || [];

  if (otherNews.length === 0) {
    return null;
  }

  const [newsItem] = otherNews;

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <List.Subheader>{t('app.home.last_news.title')}</List.Subheader>
      <LastNewsItem item={newsItem} />
      <Spacer space={1} />

      <LinkButton to="/news" style={{ position: 'absolute', right: 4, top: 4 }}>
        {t('app.home.last_news.older')}
      </LinkButton>
    </View>
  );
}

export function LastNewsItem({ item }: { item: TactileNewsItem }) {
  const { gotoInfo } = useBlockNavigation();
  const onPress = useCallback(
    () => gotoInfo(item.pageRef.pageId || (item.pageRef as any)?.page?._id),
    [gotoInfo, item.pageRef]
  );
  const date = useMemo(() => new Date(item.meta.publish.at.unix), [item]);
  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16
  const theme = useTheme();
  const finalTheme = useMemo(
    () => ({ ...theme, roundness: mobileView ? 0 : theme.roundness }),
    [theme, mobileView]
  );

  const localizedTitle = useLocalization(
    item.nameLocalized?.full,
    item.name.full
  );
  const localizedDescription = useLocalization(
    item.nameLocalized?.description,
    item.name.description
  );

  return (
    <Card theme={finalTheme} elevation={1} onPress={onPress}>
      <Card.Title
        title={localizedTitle}
        titleNumberOfLines={3}
        subtitle={`${localeDateString(date, true, false)}, ${localeTimeString(
          date
        )}`}
      />
      <TextBlock text={localizedDescription} round={{ bottom: !mobileView }} />
    </Card>
  );
}
