import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';

const SENTRY_DSN = Constants.manifest?.extra
  ? Constants.manifest['sentry-dsn']
  : null;

Sentry.init({
  debug: __DEV__,
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
});

const Browser = Sentry.Browser;
export { Browser as Sentry };
