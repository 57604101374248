import {
  EventList,
  mergeTranslations,
  Spacer,
  useLocale,
} from '@introcloud/blocks';
import { useBlockData } from '@introcloud/blocks-interface';
import { t } from 'i18n-js';
import React, { Fragment, useMemo } from 'react';
import { View } from 'react-native';
import { List } from 'react-native-paper';
import {
  getCurrentTime,
  useInaccurateTimestamp,
} from 'react-native-use-timestamp';
import { useMediaQuery } from 'react-responsive';
import { LinkButton } from '../core/LinkButton';
import { PreparedEvent } from '../hooks/useEvents';
import { useRelevantEvents } from '../hooks/useRelevantEvents';
import { extractEventData } from '../utils';
import { EventCard, NowEventData } from './NowEventCard';

mergeTranslations({
  en: {
    app: {
      now_events: {
        title: 'Happening now',
      },

      peek_events: {
        title: 'Upcoming events',
        action_calendar: 'See all',
      },

      past_events: {
        title: 'Past events',
        action_older: 'See past events',
      },
    },
  },

  nl: {
    app: {
      now_events: {
        title: 'Op dit moment',
      },

      peek_events: {
        title: 'Toekomstige evenementen',
        action_calendar: 'Zie alle',
      },

      past_events: {
        title: 'Afgelopen evenementen',
        action_older: 'Zie alle',
      },
    },
  },
});

export function PeekEvents({ focussed }: { focussed?: boolean }) {
  if (focussed) {
    return <ActivePeekEvents />;
  }

  return <InActivePeekEvents />;
}

function ActivePeekEvents() {
  const timestamp = useInaccurateTimestamp({ every: 1000 });
  const events = useRelevantEvents(timestamp);

  return (
    <Fragment>
      <PureCurrentEvents events={events.now} />
      <PureUpcomingEvents events={events.next} />
    </Fragment>
  );
}

function InActivePeekEvents() {
  const events = useRelevantEvents(getCurrentTime());

  return (
    <Fragment>
      <PureCurrentEvents events={events.now} />
      <PureUpcomingEvents events={events.next} />
    </Fragment>
  );
}

function CurrentEvents({ events }: { events: readonly PreparedEvent[] }) {
  const { getImageUrl } = useBlockData();
  const locale = useLocale();

  const preparedEvents: NowEventData[] = useMemo(() => {
    return events
      .slice(0, 6)
      .map((event): NowEventData | null => {
        const result = extractEventData(
          event,
          { loading: false },
          { getImageUrl },
          locale
        );
        if (!result) {
          return null;
        }

        return {
          ...result,
          page: event.page,
          pageRef: event.pageRef,
          largeImage: getImageUrl(
            event.image.profile || event.image.banner || '',
            'icon_512'
          ),
        };
      })
      .filter(Boolean) as NowEventData[];
  }, [events]);

  if (preparedEvents.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <List.Subheader>{t('app.now_events.title')}</List.Subheader>
      {preparedEvents.map((event, index, self) => (
        <EventCard
          key={event.id}
          {...event}
          isLast={index === self.length - 1}
        />
      ))}
    </Fragment>
  );
}

const PureCurrentEvents = React.memo(CurrentEvents);

function UpcomingEvents({ events }: { events: readonly PreparedEvent[] }) {
  const { getImageUrl } = useBlockData();
  const locale = useLocale();

  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16

  const preparedEvents = useMemo(() => {
    return events
      .slice(0, 3)
      .map(
        (event) =>
          extractEventData(
            event,
            { loading: false },
            { getImageUrl },
            locale
          ) || event._id
      );
  }, [events]);

  if (events.length === 0) {
    return (
      <View style={{ width: '100%', position: 'relative' }}>
        <View style={{ width: '100%' }}>
          <List.Subheader>{t('app.past_events.title')}</List.Subheader>
        </View>
        <LinkButton
          to="/calendar"
          style={{ position: 'absolute', right: 4, top: 4 }}
        >
          {t('app.past_events.action_older')}
        </LinkButton>

        <Spacer space={1} />
      </View>
    );
  }

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <EventList
        title={t('app.peek_events.title')}
        events={preparedEvents}
        round={{ top: !mobileView, bottom: !mobileView }}
      />
      <Spacer space={1} />

      <LinkButton
        to="/calendar"
        style={{ position: 'absolute', right: 4, top: 4 }}
      >
        {t('app.peek_events.action_calendar')}
      </LinkButton>
    </View>
  );
}

const PureUpcomingEvents = React.memo(UpcomingEvents);
