import '@introcloud/blocks/dist/TextBlock';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import I18n from 'i18n-js';

I18n.defaultLocale = Constants.manifest?.extra
  ? Constants.manifest?.extra['locale']
  : 'en';
I18n.fallbacks = { nl: 'en', en: 'nl' };

export const deviceLocale =
  ((Localization.locale || '').split('-') || [I18n.defaultLocale])[0] ??
  I18n.defaultLocale;

export function resetLocale(
  options: string[] = ['en', 'nl'],
  wanted = deviceLocale
): string {
  I18n.locale =
    options.find((value) => value === wanted) ||
    options.find((value) => value === I18n.defaultLocale) ||
    options[0];
  I18n.defaultLocale = I18n.locale;

  return I18n.locale;
}

resetLocale();
