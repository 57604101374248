import { mergeTranslations } from '@introcloud/blocks';
import { t } from 'i18n-js';
import React from 'react';

import { useTabTitle } from '../hooks/useTabTitle';
import { EventsScreen } from './EventsScreen';

mergeTranslations({
  en: {
    app: {
      calendar: {
        title: 'Calendar',
        search: 'Search for events...',
        actions: {
          past: 'See past events',
          future: 'See upcoming events',
        },
      },
      event: {
        title: 'Event',
      },
    },
  },

  nl: {
    app: {
      calendar: {
        title: 'Kalender',
        search: 'Zoeken in de kalender...',
        actions: {
          past: 'Bekijk verleden',
          future: 'Bekijk aankomend',
        },
      },
      event: {
        title: 'Event',
      },
    },
  },
});

export function EventsTab() {
  const title = useTabTitle('events', t('app.calendar.title'));

  return <EventsScreen asTab title={title} />;
}
