import { PrimaryButton, TextButton } from '@introcloud/blocks';
import { t } from 'i18n-js';
import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Paragraph } from 'react-native-paper';

import { DialogContentProps } from './types';

export function ManualDialogContent({ onDismiss }: DialogContentProps) {
  return (
    <Fragment>
      <Paragraph>{t('app.goals.goal.manual.description')}</Paragraph>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          disabled
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          {t('app.goals.actions.submit')}
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          {t('app.goals.actions.close')}
        </TextButton>
      </View>
    </Fragment>
  );
}
