import { TabParamList } from '@introcloud/tabs';
import { useRoute } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { RouteProp } from '../core/Routes';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { BlockProvidedPage } from './Page';

export function PageScreen() {
  const {
    params: { id: page, params: { id } = { id: undefined } },
  } = useRoute<RouteProp<'Info'>>() as any;
  const { values } = useCompanyTabs();
  const fallback = useMemo(
    () => ({
      screen: 'Tabs' as const,
      params: {
        screen: (values.some((tab) => tab.tab === 'information')
          ? 'Information'
          : values.some((tab) => tab.tab === 'home')
          ? 'Home'
          : values[0].tab[0].toLocaleUpperCase() +
            values[0].tab.slice(1)) as keyof TabParamList,
      },
    }),
    [values]
  );

  return (
    <BlockProvidedPage
      page={page || id}
      hideBack={false}
      fallBack={fallback}
      screen="PageScreen"
      showLogo={(page || id) === '6103baec66d6226acde1ae52'}
    />
  );
}
