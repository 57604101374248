import { useLocaleAction, useTranslationAction } from '@introcloud/blocks';
import { useNavigation } from '@react-navigation/native';
import React, { memo, useCallback } from 'react';
import {
  Platform,
  StatusBar,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Appbar, Avatar, ThemeProvider, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
import { useCompany } from '../hooks/useCompany';
import { RouteParamList } from './Routes';

export type ValidRoutes = Exclude<keyof RouteParamList, 'NotFound'>;

type RootRoute<
  T extends ValidRoutes = Exclude<keyof RouteParamList, 'NotFound'>
> = {
  screen: T;
  params: RouteParamList[T];
};

export type FallbackRoute<T extends ValidRoutes> = RootRoute<T>;
export interface HeaderProps<T extends ValidRoutes> {
  title: string | null | undefined;
  subTitle?: string | null | undefined;
  backFallback?: FallbackRoute<T> | null;
  hideProfile?: boolean;
  showTranslate?: boolean;
  hideBack?: boolean;
  colorOverride?: string;
  style?: StyleProp<ViewStyle>;
  image?: string | null;
  children?: React.ReactNode;
  showLogo?: boolean;
}

function Header_<T extends ValidRoutes = ValidRoutes>({
  title,
  subTitle,
  backFallback,
  hideProfile,
  hideBack,
  showTranslate,
  colorOverride,
  style,
  children,
  image,
  showLogo,
}: HeaderProps<T>) {
  const company = useCompany();
  const theme = useTheme();
  const [current, translate] = useLocaleAction() as [
    string,
    (next?: string | null) => void
  ];
  const { canGoBack, goBack, navigate, replace } = useNavigation<any>();

  const localizationOptions = company?.settings.localization;
  const languages =
    !localizationOptions || localizationOptions.length === 0
      ? ['en', 'nl']
      : localizationOptions;
  const translated = languages?.length === 2 && current === languages[1];

  const { top } = useSafeAreaInsets();
  const showBack = !hideBack && (canGoBack() || backFallback);

  const onGoBack = useCallback(() => {
    if (canGoBack()) {
      goBack();
      return;
    }

    const b = backFallback!;

    replace(b.screen, b.params);
  }, [canGoBack, goBack, replace, backFallback]);

  const onGotoProfile = useCallback(() => {
    navigate('Profile', undefined);
  }, [navigate]);

  const actualTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colorOverride || theme.colors.primary,
    },
  };

  return (
    <ThemeProvider theme={actualTheme}>
      <Appbar.Header
        style={[styles.header, style]}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        {showBack && <Appbar.BackAction onPress={onGoBack} />}
        {image ? (
          <Avatar.Image
            source={{ uri: image, width: 64, height: 64 }}
            size={36}
            style={{ marginLeft: 6, marginRight: -6 }}
          />
        ) : showLogo ? (
          <TactilePlantFairLogo />
        ) : null}
        <Appbar.Content
          title={showLogo ? 'Tactile Plant Fair' : title}
          subtitle={subTitle}
          titleStyle={
            showLogo ? { color: '#1F5739', fontWeight: 'bold' } : undefined
          }
          subtitleStyle={
            Platform.OS === 'web' && subTitle ? { height: 20 } : undefined
          }
        />

        {children}

        {showTranslate && languages && languages.length > 1 && (
          <Appbar.Action
            icon={translated ? 'translate-off' : 'translate'}
            onPress={() =>
              translate(
                languages[(languages.indexOf(current) + 1) % languages.length]
              )
            }
          />
        )}
        {!hideProfile && (
          <Appbar.Action icon="account-circle" onPress={onGotoProfile} />
        )}
      </Appbar.Header>
    </ThemeProvider>
  );
}

function TactilePlantFairLogo() {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 1000 1000"
      fill="none"
      style={{ marginLeft: 12, marginRight: -6 }}
    >
      <G clipPath="url(#clip0_197:12)">
        <Path
          d="M353.606 900.095c-52.307 0-100.633-27.907-126.786-73.2-41.607-72.073-104.807-181.532-146.414-253.605-26.153-45.294-26.153-101.1 0-146.4 41.607-72.067 104.807-181.526 146.414-253.593 26.153-45.299 74.479-73.199 126.786-73.199h292.827c52.307 0 100.647 27.9 126.78 73.199 41.6 72.067 104.801 181.526 146.401 253.593 26.2 45.3 26.2 101.106 0 146.4-41.6 72.073-104.801 181.532-146.401 253.605-26.133 45.293-74.473 73.2-126.78 73.2H353.606zm107.701-606.758a26.428 26.428 0 00-2.007-22.08c-4.174-7.233-9.747-16.88-14.213-24.62a26.42 26.42 0 00-22.881-13.206h-26.94a26.405 26.405 0 00-22.246 12.173c-6.387 10.087-14.047 24.586-14.047 36.233 0 20.007 8.16 49.853 8.16 86.573v209.513c0 35.2-4.414 69.339-4.414 91.926 0 15.607 3.348 29.64 15.368 41.033 11.666 11.047 39.36 16.46 50.253 23.014 12.88 7.746 22.366 21.246 30.046 27.253a26.43 26.43 0 0016.281 5.613h38.473a26.435 26.435 0 0015.133-4.767c9.127-6.373 18.947-24.193 32.547-32.826 22.153-14.067 43.44-8.827 52.273-24.12a80018.46 80018.46 0 0012.694-21.993 26.417 26.417 0 000-26.42c-3.947-6.833-8.747-15.154-12.694-21.987a26.418 26.418 0 00-22.88-13.206H564.82a26.407 26.407 0 00-22.873 13.206c-3.967 6.86-8.794 15.22-12.754 22.08a26.41 26.41 0 01-22.88 13.214H484.08a26.42 26.42 0 01-24.88-17.547c-3.433-9.613-11.074-25.407-11.074-35.62 0-18.447.32-104.826.587-115.28.367-14.326 12.08-25.746 26.407-25.746h36.447c9.44 0 18.16-5.034 22.88-13.207 3.946-6.84 8.753-15.16 12.7-21.993a26.451 26.451 0 000-26.42c-3.947-6.833-8.754-15.153-12.7-21.993a26.417 26.417 0 00-22.88-13.207H475.12c-14.327 0-26.04-11.42-26.407-25.74-.18-6.873-.353-13.586-.46-17.94-.44-16.993 9.374-31.58 13.054-41.913z"
          fill="#3DA96F"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_197:12">
          <Path fill="#fff" d="M0 0H1000V1000H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

const StableBackAction = memo(Appbar.BackAction);

export function DetachedHeader({
  title,
  subTitle,
  colorOverride,
  style,
}: {
  title: string | null | undefined;
  subTitle?: string | null | undefined;
  colorOverride?: string;
  style?: StyleProp<ViewStyle>;
}) {
  const theme = useTheme();
  const { top } = useSafeAreaInsets();

  const actualTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colorOverride || theme.colors.primary,
    },
  };

  return (
    <ThemeProvider theme={actualTheme}>
      <Appbar.Header
        style={[styles.header, style]}
        statusBarHeight={Platform.select({
          ios: StatusBar.currentHeight || top || 20,
          default: undefined,
        })}
      >
        <Appbar.Content title={title} subtitle={subTitle} />
      </Appbar.Header>
    </ThemeProvider>
  );
}

export const Header = memo(Header_);

const styles = StyleSheet.create({
  header: {
    zIndex: 1,
    maxWidth: Platform.OS !== 'ios' || !Platform.isPad ? 720 : '100%',
    margin: 'auto',
    width: '100%',
  },
});
