import { BlockQueryKeys } from '@introcloud/blocks-interface';
import Constants from 'expo-constants';
import {
  createRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import {
  AnyMemoryValue,
  AnyValue,
  SecureStoredMemoryValue,
  StoredMemoryValue,
} from '../storage';
import { queryClient } from './QueryCache';

export type Permits = Readonly<{
  lastPermit: string | null;
  permits: Readonly<Record<string, AnyMemoryValue<Permit>>>;
  hydrated: boolean;
}>;

export type PermitsRef = {
  lastPermit: string | null;
  permits: Record<string, string>;
};

export type Permit = {
  domainFull: string;
  token: string;
  expiresAt: number;
};

const slug = Constants.manifest?.slug || 'introcloud';
const AUTHENTICATION: AnyMemoryValue<PermitsRef> &
  Pick<StoredMemoryValue<PermitsRef>, 'hydrate'> = Platform.select({
  web: new StoredMemoryValue<PermitsRef>('authentication.v4.web', false, {
    lastPermit: null,
    permits: {},
  }) as AnyMemoryValue<PermitsRef> &
    Pick<StoredMemoryValue<PermitsRef>, 'hydrate'>,
  default: new SecureStoredMemoryValue<PermitsRef>(
    `authentication.v4.native.${slug}.`,
    false,
    {
      lastPermit: null,
      permits: {},
    }
  ) as AnyMemoryValue<PermitsRef> &
    Pick<StoredMemoryValue<PermitsRef>, 'hydrate'>,
});

const PERMITS: Record<string, AnyMemoryValue<Permit>> = {};
const AUTHENTICATION_LISTENERS: ((next: AnyValue<Permit | null>) => void)[] =
  [];

async function hydrate() {
  const initial = await AUTHENTICATION.hydrate().then(
    () => AUTHENTICATION.current
  );

  if (!initial) {
    // console.log({ initial });
    return;
  }

  return Promise.all(
    Object.values(initial.permits).map((permitRef) =>
      getPermitStorage(permitRef)
    )
  );
}

async function getPermitStorage(key: string) {
  if (PERMITS[key]) {
    return PERMITS[key];
  }

  const value = Platform.select({
    web: new StoredMemoryValue<Permit>(`authentication.v4.web.${key}`, false),
    default: new SecureStoredMemoryValue<Permit>(
      `authentication.v4.native.${slug}.${key}`,
      false
    ) as unknown as StoredMemoryValue<Permit>,
  });

  // Load initial value if any
  await value.hydrate();

  PERMITS[key] = value as AnyMemoryValue<Permit>;
  return PERMITS[key];
}

function hydrateAndPrepare() {
  return hydrate()
    .then(
      () => {},
      () => {}
    )
    .then(() => {
      return getPermitStorage(
        permitKey(AUTHENTICATION.current?.lastPermit || '')
      );
    })
    .then(
      (): Permits =>
        (HYDRATED.current = {
          lastPermit: AUTHENTICATION.current?.lastPermit ?? null,
          permits: PERMITS,
          hydrated: true,
        })
    );
}

export const HYDRATE = hydrateAndPrepare();

const HYDRATED: { current: undefined | Permits } = {
  current: undefined,
};

export function usePermits() {
  const [current, setCurrent] = useState(() => HYDRATED.current);

  // Update if authentication (last permit) changes
  useEffect(() => {
    return AUTHENTICATION.subscribe((next) => {
      const lastPermit = next?.lastPermit ?? null;

      setCurrent((prev) => ({
        permits: PERMITS,
        lastPermit,
        hydrated: prev?.hydrated || false,
      }));
    });
  }, []);

  // Wait for hydration if necessary
  useEffect(() => {
    let mounted = true;

    if (!current) {
      hydrateAndPrepare().then((permits) => {
        if (mounted) {
          setCurrent(permits);
        }
      });
    } else {
      // console.log({ current });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const remove = useCallback(
    async (permit: string) => {
      if (!current?.hydrated) {
        return;
      }

      setCurrent((prev) => {
        if (!prev) {
          return undefined;
        }

        const nextPermits = { ...prev.permits };
        delete nextPermits[permit];

        return { ...prev, permits: nextPermits };
      });

      return getPermitStorage(permit)
        .then((storage) => storage.emit(undefined))
        .then(() => delete PERMITS[permit]);
    },
    [current]
  );

  return { current, remove };
}

export const NEXT_DOMAIN = createRef<string | undefined>() as MutableRefObject<
  string | undefined
>;

export async function suspend(nextDomain?: string) {
  if (!HYDRATED.current) {
    await HYDRATE;
  }

  if (typeof nextDomain === 'string' || nextDomain === undefined) {
    NEXT_DOMAIN.current = nextDomain;
  }

  // A lot like logout, but doesn't delete the session

  const next = {
    lastPermit: null,
    permits: {} as Record<string, string>,
  };

  Object.keys(AUTHENTICATION.current?.permits ?? {}).forEach((key) => {
    next.permits[permitKey(key)] = key;
  });

  emitLogoutEvent();

  return AUTHENTICATION.emit(next);
}

export async function logout() {
  if (!HYDRATED.current) {
    await HYDRATE;
  }

  NEXT_DOMAIN.current = undefined;

  const next = {
    lastPermit: null,
    permits: {} as Record<string, string>,
  };

  const prevKey = AUTHENTICATION.current?.lastPermit;

  // Remove previous from storage
  if (prevKey) {
    const prevStorage = await getPermitStorage(prevKey);
    if (prevStorage) {
      prevStorage.emit(undefined, true, false);
    }
  }

  // Remove previous from reference list
  Object.keys(AUTHENTICATION.current?.permits ?? {})
    .filter((key) => key !== prevKey)
    .forEach((key) => {
      next.permits[permitKey(key)] = key;
    });

  emitLogoutEvent();

  return AUTHENTICATION.emit(next);
}

export async function login(permit: Permit) {
  if (!HYDRATED.current) {
    await HYDRATE;
  }

  NEXT_DOMAIN.current = undefined;

  const prevKey = AUTHENTICATION.current?.lastPermit;
  const nextKey = permitKey(permit.domainFull);

  if (nextKey === '-') {
    throw new Error('No domain in permit');
  }

  const nextStorage = await getPermitStorage(nextKey);

  // Store permit
  await nextStorage.emit(permit, true, false);

  const next = {
    lastPermit: nextKey,
    permits: {
      [nextKey]: nextKey,
    },
  };

  // Copy over old permit refs
  Object.keys(AUTHENTICATION.current?.permits ?? {})
    .filter((key) => key !== nextKey)
    .forEach((key) => {
      next.permits[permitKey(key)] = key;
    });

  if (prevKey !== nextKey) {
    // Company has changed, so trigger a logout event
    emitLogoutEvent();
  }

  return AUTHENTICATION.emit(next)
    .then(() => emitLoginEvent(permit))
    .then(() => AUTHENTICATION.current);
}

export async function resolve(key: string) {
  if (!HYDRATED.current) {
    await HYDRATE;
  }

  const memoryValue = await getPermitStorage(permitKey(key));
  return memoryValue.current as Permit | null;
}

export function useCurrentPermit() {
  const { current: permits } = usePermits();

  if (permits === undefined) {
    return undefined;
  }

  return permits?.permits[permitKey(permits.lastPermit || '')] ?? null;
}

function emitLoginEvent(permit: Permit) {
  queryClient.removeQueries(BlockQueryKeys.event('first-visible'));
  AUTHENTICATION_LISTENERS.forEach((listener) => listener(permit));
}

function emitLogoutEvent() {
  AUTHENTICATION_LISTENERS.forEach((listener) => listener(null));
}

export function subscribe(listener: (next: AnyValue<Permit | null>) => void) {
  AUTHENTICATION_LISTENERS.push(listener);
  return function unsubscribe() {
    const index = AUTHENTICATION_LISTENERS.indexOf(listener);

    if (index !== -1) {
      AUTHENTICATION_LISTENERS.splice(index, 1);
    }
  };
}

export function permitKey(domain: string): string {
  if (!domain) {
    return '-';
  }

  return domain.replace(/https?:\/\//, '');
}
