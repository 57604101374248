import {
  TactileChatProfileAction,
  TactileChatProfileAnswer,
  TactileGameMap,
  TactileGameMapPlace,
} from '@introcloud/api-client';
import {
  AccentButton,
  PrimaryButton,
  useLocalization,
} from '@introcloud/blocks';
import { useBlockNavigation } from '@introcloud/blocks-interface';
import React, { Fragment, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  Avatar,
  Card,
  IconButton,
  List,
  Paragraph,
  useTheme,
} from 'react-native-paper';
import Animated from 'react-native-reanimated';
import { useChatImage } from '../chats/useChatImage';
import { useChatInitials } from '../chats/useChatInitials';
import { useChatUserProfile } from '../chats/useChatUserProfile';
import { openExternalUrl } from '../open';
import { useGameMapInteraction } from './GameMapInteraction';
import { Nipple } from './Nipple';

export function GameMapOverlay({ gameMap }: { gameMap: TactileGameMap }) {
  return (
    <Fragment>
      <Nipple key="nipple" />
      <Callout key="callout" gameMap={gameMap} />
    </Fragment>
  );
}

function Callout({ gameMap }: { gameMap: TactileGameMap }) {
  const [selected, setSelected] = useState<null | string>(null);

  const {
    selected: { onSelected },
  } = useGameMapInteraction();

  useEffect(() => {
    return onSelected(setSelected);
  }, [onSelected, setSelected]);

  if (!selected) {
    return null;
  }

  const [type, id] = selected.split('/');

  switch (type) {
    case 'player': {
      return <PlayerCallout playerId={id} />;
    }

    case 'mapPlace': {
      const place = gameMap.mapPlaceRef.find(
        (place) => place.mapPlaceId === id
      ) as unknown as undefined | { mapPlace: TactileGameMapPlace };
      if (!place || !place.mapPlace) {
        return null;
      }

      return <MapPlaceCallout place={place.mapPlace} />;
    }

    default: {
      return null;
    }
  }
}

function PlayerCallout({ playerId }: { playerId: string }) {
  const {
    broadcastRoom,
    selected: { select },
  } = useGameMapInteraction();
  const info = useChatUserProfile({ id: playerId });
  const initials = useChatInitials(info);
  const image = useChatImage(info, 'icon_128');

  const { gotoChat } = useBlockNavigation();

  return (
    <Animated.View
      style={[
        {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 10,
          position: 'absolute',
        },
        StyleSheet.absoluteFillObject,
      ]}
      pointerEvents="auto"
    >
      <Card
        style={{
          position: 'absolute',
          top: 24,
          left: 24,
          right: 24,
          zIndex: 10,
        }}
        elevation={6}
      >
        <Card.Title
          title={info?.name?.full}
          subtitle={info?.name?.description || ''}
          left={(props) =>
            image ? (
              <Avatar.Image
                source={{ uri: image, width: 128, height: 128 }}
                {...props}
              />
            ) : (
              <Avatar.Text label={initials} {...props} />
            )
          }
          right={(props) => (
            <IconButton {...props} icon="close" onPress={() => select(null)} />
          )}
        />
        <Answers answers={info?.answers} />
        <Card.Actions
          style={{ paddingRight: 16, paddingBottom: 16, paddingLeft: 16 }}
        >
          <PrimaryButton
            icon="forum"
            style={{ marginRight: 8 }}
            onPress={() => {
              gotoChat(`:user/${JSON.stringify({ user: playerId })}`);
              setTimeout(() => broadcastRoom('game-map.meta-room.private'), 1);
            }}
          >
            Start chat
          </PrimaryButton>

          {info?.actions
            ? info.actions.map((action, index) => (
                <Action key={index} {...action} />
              ))
            : null}
        </Card.Actions>
      </Card>
    </Animated.View>
  );
}

function Answers({
  answers,
}: {
  answers: TactileChatProfileAnswer[] | undefined;
}) {
  if (!answers || answers.length === 0) {
    return null;
  }

  return (
    <ScrollView
      contentContainerStyle={{
        margin: 0,
        paddingHorizontal: 0,
        maxHeight: 100,
      }}
    >
      {answers.map((answer, i) => (
        <Answer key={i} {...answer} />
      ))}
    </ScrollView>
  );
}

function Answer({
  kind,
  label,
  value,
}: TactileChatProfileAnswer & { kind?: TactileChatProfileAnswer['type'] }) {
  switch (kind!) {
    case 'input': {
      return (
        <List.Item
          title={value}
          description={label}
          descriptionStyle={{ marginTop: 4 }}
        />
      );
    }

    case 'textarea': {
      return (
        <Fragment>
          <List.Subheader>{label}</List.Subheader>
          <Paragraph>{value}</Paragraph>
        </Fragment>
      );
    }

    case 'toggle': {
      return null;
    }

    default: {
      return null;
    }
  }
}

function Action({ kind, value }: TactileChatProfileAction) {
  const {
    colors: { primary },
  } = useTheme();

  switch (kind) {
    case 'linkedin': {
      return (
        <AccentButton
          icon="linkedin"
          accessibilityLabel="View LinkedIn profile"
          style={{ marginRight: 8 }}
          onPress={() => openExternalUrl(value, primary)}
        >
          View
        </AccentButton>
      );
    }
    default: {
      return null;
    }
  }
}

function MapPlaceCallout({ place }: { place: TactileGameMapPlace }) {
  const {
    broadcastRoom,
    selected: { select },
  } = useGameMapInteraction();
  const { gotoInfo } = useBlockNavigation();
  const localizedTitle = useLocalization(
    place.nameLocalized?.full,
    place.name.full
  );
  const localizedDescription = useLocalization(
    place.nameLocalized?.description,
    place.name.description
  );

  return (
    <Animated.View
      style={[
        {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          zIndex: 10,
          position: 'absolute',
        },
        StyleSheet.absoluteFillObject,
      ]}
      pointerEvents="auto"
    >
      <Card
        style={{ position: 'absolute', top: 24, left: 24, right: 24 }}
        elevation={6}
      >
        <Card.Title
          title={localizedTitle}
          subtitle={localizedDescription}
          subtitleNumberOfLines={3}
          right={(props) => (
            <IconButton {...props} icon="close" onPress={() => select(null)} />
          )}
        />
        <Card.Actions>
          {place.pageRef.pageId ? (
            <PrimaryButton
              onPress={() => {
                gotoInfo(place.pageRef.pageId!);
                setTimeout(() => broadcastRoom(place._id), 1);
              }}
            >
              Enter
            </PrimaryButton>
          ) : null}
        </Card.Actions>
      </Card>
    </Animated.View>
  );
}
