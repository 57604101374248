import type { TactileCompanyTabs } from '@introcloud/api-client';
import {
  ProvideBlockNavigation,
  useBlockData,
  useDimensions,
} from '@introcloud/blocks-interface';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { Fragment, useCallback, useMemo } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Card, HelperText, useTheme } from 'react-native-paper';
import {
  localeDateString,
  localeLongWeekDayString,
} from 'react-native-time-helpers';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import { useCompanyEventDays } from '../hooks/useCompanyEventDays';
import { getMidDayId, useDayBoundaries } from '../hooks/useDayBoundaries';
import { PreparedEvent, useEvents } from '../hooks/useEvents';
import { useProvideBlockNavigation } from '../hooks/useProvideBlockNavigation';
import { useTabTitle } from '../hooks/useTabTitle';
import { isDuringDay } from './isDuringDay';

export function EventDaysTab() {
  const days = useCompanyEventDays();
  const navigation = useProvideBlockNavigation();

  if (!days) {
    return (
      <HelperText type="error">Company days are not configured</HelperText>
    );
  }
  return (
    <ProvideBlockNavigation navigation={navigation}>
      <EventDays configuration={days} />
    </ProvideBlockNavigation>
  );
}

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const SINGLE_COLUMN_WIDTH_LIMIT = 737;
const TALL_CARD_HEIGHT_LIMIT = 1200;
const PADDING = 6;

function EventDays({
  configuration,
}: {
  configuration: TactileCompanyTabs['configuration']['event-days'] & {
    hideLabels?: boolean;
  };
}) {
  const title = useTabTitle('event-days', 'Calendar');
  const { data: events } = useEvents();

  const { width: windowWidth, height: windowHeight } = useDimensions('window');
  const perRow = windowWidth > SINGLE_COLUMN_WIDTH_LIMIT ? 2 : 1;
  const cardWidth =
    Math.round(Math.min(720 || Infinity, windowWidth) - PADDING * 2) / perRow;
  const cardHeight = Math.floor(
    windowHeight > TALL_CARD_HEIGHT_LIMIT || perRow > 1
      ? (cardWidth / 4) * 3
      : (cardWidth / 12) * 5
  );

  const flexBasis = cardWidth - PADDING * 2;
  const days = useMemo(() => {
    // These durations are stored on midnight-ish
    const {
      duration: {
        start: { unix: startUnix },
        end: { unix: endUnix },
      },
    } = configuration;

    if (startUnix > endUnix) {
      return [];
    }

    // The calculations here are done so that 2020-01-01 00:00 is _likely_ to
    // be reported as 2020-01-01 for all locales. This works by moving the
    // date to 2020-01-01 12:00, and then performing all operations.

    const startDate = new Date(startUnix + DAY_IN_MILLISECONDS / 2);
    const endDate = new Date(endUnix + DAY_IN_MILLISECONDS / 2);
    const startMidDay = getMidDayId(startDate);
    const endMidDate = getMidDayId(endDate);

    // Loop through all the days and get the images
    let currentDate = new Date(startMidDay);
    const endDateUnix = new Date(endMidDate).getTime();

    const days: { date: number; image: string | undefined }[] = [];
    while (currentDate.getTime() <= endDateUnix) {
      const midDay = getMidDayId(currentDate).split('T').shift();

      days.push({
        date: currentDate.getTime(),
        image: configuration.images[midDay || ''],
      });

      currentDate = new Date(currentDate.getTime() + DAY_IN_MILLISECONDS);
    }

    return days;
  }, [configuration]);

  if (days.length === 0) {
    return <HelperText type="error">0 days to show</HelperText>;
  }

  return (
    <BlockProvision screen="EventDays">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          // maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header title={title} subTitle={undefined} hideBack showTranslate />
        <ScrollView
          nativeID="scroller"
          style={{ flex: 1, maxHeight: '100%' }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 56,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              margin: PADDING,
            }}
          >
            {days.map((day) => (
              <CalendarDayCard
                key={day.date}
                day={day}
                width={flexBasis}
                cardWidth={flexBasis}
                cardHeight={cardHeight}
                mode={days.length > 6 ? 'long' : 'short'}
                events={events}
                hideLabels={
                  configuration.hideLabels === undefined
                    ? true
                    : configuration.hideLabels
                }
              />
            ))}
          </View>
        </ScrollView>
      </View>
    </BlockProvision>
  );
}

function CalendarDayCard({
  day,
  mode,
  width,
  cardWidth,
  cardHeight,
  events,
  hideLabels,
}: {
  day: { date: number; image: string | undefined };
  mode: 'long' | 'short';
  width: number;
  cardWidth: number;
  cardHeight: number;
  events: readonly PreparedEvent[] | null | undefined;
  hideLabels: boolean;
}) {
  const [dayStart, dayEnd] = useDayBoundaries(day.date);

  const { getImageUrl } = useBlockData();
  const { navigate } = useNavigation<any>();
  const doNavigate = useCallback(() => {
    navigate('EventDay', {
      day: getMidDayId(dayStart).split('T').shift(),
    });
  }, [navigate, day.date]);

  const {
    colors: { placeholder },
    roundness,
  } = useTheme();

  const imageSource = getImageUrl(day.image || '', 'icon_512') || '';
  const anyEventToday = useMemo(
    () =>
      !events ||
      events.some(
        (event) =>
          event.hierarchy.showInCalendar &&
          isDuringDay(
            dayStart,
            dayEnd,
            event.duration.start.unix,
            event.duration.end.unix
          )
      ),
    [events, dayStart, dayEnd]
  );

  if (!anyEventToday) {
    return null;
  }

  return (
    <Card
      onPress={doNavigate}
      style={[
        {
          flexBasis: width,
          overflow: 'hidden',
          zIndex: 1,
          position: 'relative',
          borderRadius: roundness,
          margin: PADDING,
        },
        Platform.OS === 'web' ? ({ cursor: 'pointer' } as any) : {},
      ]}
    >
      <Image
        source={{
          uri: imageSource,
          width: cardWidth,
          height: cardHeight,
        }}
        width={cardWidth}
        height={cardHeight}
        resizeMode="cover"
        style={{
          width: cardWidth,
          height: cardHeight,
          backgroundColor: placeholder,
        }}
      />

      {hideLabels ? null : (
        <Fragment>
          <LinearGradient
            colors={[
              'transparent',
              'transparent',
              'rgba(0, 0, 0, .3)',
              'rgba(0, 0, 0, .6)',
            ]}
            style={[StyleSheet.absoluteFill]}
          />

          <Card.Title
            title={
              mode === 'short'
                ? localeLongWeekDayString(new Date(day.date))
                : localeDateString(new Date(day.date))
            }
            titleStyle={{ color: '#fff' }}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              zIndex: 4,
            }}
          />
        </Fragment>
      )}
    </Card>
  );
}
