import { MaterialCommunityIcons } from '@expo/vector-icons';
import { fetchApplication, TactileCompany } from '@introcloud/api-client';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { useIsMounted } from 'use-is-mounted';
import { MULTI_COMPANY_ENABLED } from '../features';
import { SHOULD_DEBUG_FETCH } from '../utils';
import { COMPANY } from './useCompany';

export function useSplashResources() {
  const [isSplashReady, setIsSplashReady] = React.useState(false);
  const isMountedRef = useIsMounted();

  React.useEffect(() => {
    const splashImage = require('../../assets/splash.png');
    Asset.fromModule(splashImage)
      .downloadAsync()
      .then(
        () => isMountedRef.current && setIsSplashReady(true),
        (error) => {
          console.warn(error);
          isMountedRef.current && setIsSplashReady(true);
        }
      );
  }, [setIsSplashReady, isMountedRef]);

  return isSplashReady;
}

export function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const isMountedRef = useIsMounted();

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await SplashScreen.preventAutoHideAsync();
        const fonts = Font.loadAsync(MaterialCommunityIcons.font);
        const company = refreshFromApi().then((company) => {
          if (isMountedRef.current && company) {
            return COMPANY.emit(company).then(() => company);
          }

          return null;
        });

        await Promise.all([fonts, company]);
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        if (isMountedRef.current) {
          setLoadingComplete(true);
        }
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}

export async function refreshFromApi(): Promise<TactileCompany | null> {
  if (MULTI_COMPANY_ENABLED) {
    return null;
  }

  const domain = Constants.manifest?.extra?.endpoint as string;
  const endpoint = domain.endsWith('/api') ? domain : domain + '/api';

  if (
    !domain ||
    domain === 'api.tactile.events' ||
    domain === 'https://api.tactile.events'
  ) {
    return null;
  }

  // console.log({ domain });

  try {
    return await fetchApplication(endpoint, undefined, SHOULD_DEBUG_FETCH);
  } catch (error) {
    console.error(`Could not refresh company from API ${endpoint}`);
    console.error(error);
    return null;
  }
}
