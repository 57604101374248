import { PrimaryButton } from '@introcloud/blocks';
import { t } from 'i18n-js';
import * as React from 'react';
import { Caption } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { useCurrentDomain } from '../hooks/useAuthentication';
import { useRemoteCompany } from '../hooks/useCompany';
import { useSurfAuth } from './useSurfAuth';

export function MaybeSurfConext({ domain }: { domain?: string }) {
  const baseDomain = useCurrentDomain();
  const finalDomain = domain ?? baseDomain;
  const { data: company } = useRemoteCompany(finalDomain);

  if (
    !company ||
    finalDomain === 'https://api.tactile.events' ||
    finalDomain === 'https://app.tactile.events'
  ) {
    return null;
  }

  const apiSurfConext = company.apiSurfConext;
  const settings = company.settings ?? {};
  const authentication = settings.authentication;

  if (
    typeof authentication !== 'object' ||
    !authentication ||
    typeof apiSurfConext !== 'object' ||
    !apiSurfConext
  ) {
    return null;
  }

  if (authentication.surfConext.active) {
    const clientId = apiSurfConext.client_id;
    const authorize_url = apiSurfConext.authorize_url;
    const token_url = apiSurfConext.token_url;

    if (!clientId || !token_url || !authorize_url) {
      return null;
    }

    return (
      <React.Fragment>
        <SurfConext
          domainFull={finalDomain}
          clientId={clientId}
          discovery={{ token: token_url, authorize: authorize_url }}
        />
        <Caption style={{ textAlign: 'center', marginBottom: 16 }}>
          {t('app.login.or')}
        </Caption>
      </React.Fragment>
    );
  }

  return null;
}

function SurfConext({
  domainFull,
  clientId,
  discovery,
}: {
  domainFull: string;
  clientId: string;
  discovery: { token: string; authorize: string };
}) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState(false);

  const login = useSurfAuth(
    domainFull,
    clientId,
    discovery.authorize,
    discovery.token
  );

  return (
    <PrimaryButton
      disabled={loading}
      style={{ marginBottom: 12, maxWidth: '100%' }}
      loading={loading}
      onPress={() => {
        setLoading(true);

        login().then(() => isMounted.current && setLoading(false));
      }}
    >
      {t('app.login.surfconext_action')}
    </PrimaryButton>
  );
}
