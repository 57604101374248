import { TactileGoal } from '@introcloud/api-client';
import {
  AccentButton,
  PrimaryButton,
  TextButton,
  useLocale,
} from '@introcloud/blocks';
import { t } from 'i18n-js';
import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import { Menu } from 'react-native-paper';

import { DialogContentProps } from './types';

export function MenuDialogContent({
  answers,
  loading,
  onSubmit,
  onDismiss,
}: {
  answers: TactileGoal['answers'];
} & DialogContentProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<{
    _id: string;
    label: string;
  } | null>(null);
  useLocale();

  const [menuActive, setMenuActive] = useState(false);

  return (
    <Fragment>
      <Menu
        visible={menuActive}
        onDismiss={() => setMenuActive(false)}
        anchor={
          <AccentButton onPress={() => setMenuActive(true)}>
            {selectedAnswer?.label || t('app.goals.actions.select_one')}
          </AccentButton>
        }
      >
        {answers.map((answer) => (
          <Menu.Item
            key={answer._id}
            title={answer.label}
            onPress={() => {
              setSelectedAnswer(answer);
              setMenuActive(false);
            }}
          />
        ))}
      </Menu>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          onPress={() => onSubmit(selectedAnswer!._id)}
          disabled={loading || !selectedAnswer}
          loading={loading}
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          {t('app.goals.actions.submit')}
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          {t('app.goals.actions.close')}
        </TextButton>
      </View>
    </Fragment>
  );
}
