import { TactileLocation } from '@introcloud/api-client';
import {
  AccentButton,
  DirectionsMenu,
  PrimaryButton,
  useLocale,
  useLocalization,
} from '@introcloud/blocks';
import {
  useBlockImageUrl,
  useBlockNavigation,
  useDimensionsIdle,
} from '@introcloud/blocks-interface';
import { localize } from '@introcloud/blocks/dist/useLocale';
import { LinearGradient } from 'expo-linear-gradient';
import { t } from 'i18n-js';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
// import { motion } from 'framer-motion';
import {
  FlatList,
  Image,
  ListRenderItemInfo,
  PixelRatio,
  Platform,
  View,
} from 'react-native';
import { Card, useTheme } from 'react-native-paper';
import { PreparedLocation } from '../hooks/useLocations';
import { openExternalUrl } from '../open';

const CONTAINER_PADDING = 20;
const CARD_RATIO = 335 / 197;
const CARD_PADDING = 4;
const MIN_WIDTH_PER_CARD = 240;

const CARDS_ON_SCREEN_BREAKPOINTS = {
  [MIN_WIDTH_PER_CARD * 2.5]: 1,
  [MIN_WIDTH_PER_CARD * 4]: 2,
  [MIN_WIDTH_PER_CARD * 6]: 3,
};

export function MapSwiperOverlay_({
  selected,
  locations,
  filter,
}: {
  selected?: TactileLocation | null;
  locations?: readonly PreparedLocation[];
  filter?: string | null;
}) {
  const flatListRef = useRef<FlatList | null>(null);
  const { width } = useDimensionsIdle('window');

  const breakpoint = Object.keys(CARDS_ON_SCREEN_BREAKPOINTS).find(
    (breakpoint) => Number(breakpoint) > width
  );

  const cardsOnScreen = breakpoint
    ? CARDS_ON_SCREEN_BREAKPOINTS[Number(breakpoint)]
    : 4;

  const cardWidth = PixelRatio.roundToNearestPixel(
    (width - CONTAINER_PADDING * 2 - CARD_PADDING * cardsOnScreen) /
      cardsOnScreen
  );

  const cardHeight = PixelRatio.roundToNearestPixel(cardWidth / CARD_RATIO);

  const locale = useLocale();
  const data = useMemo(() => {
    if (!locations) {
      return [];
    }
    return locations.filter((location) => {
      const tags =
        localize(location.nameLocalized?.tag, location.name.tag, locale) || [];
      return (
        typeof filter !== 'string' ||
        selected?._id === location._id ||
        tags.includes(filter) ||
        (filter === '' && tags.length === 0)
      );
    });
  }, [locations, filter, selected?._id]);

  useEffect(() => {
    if (flatListRef.current && data && selected) {
      const index = data.findIndex((l) => l._id === selected._id);

      if (index === -1) {
        return;
      }

      flatListRef.current.scrollToIndex({
        animated: typeof filter !== 'string',
        viewPosition: 0.5,
        index,
      });
    }
  }, [selected, data]);

  const renderItem = useCallback(
    ({ item: location }: ListRenderItemInfo<PreparedLocation>) => {
      return (
        <PureLocationSwipeCard
          location={location}
          key={location._id}
          cardWidth={cardWidth}
          cardHeight={cardHeight}
          selected={selected?._id === location._id}
        />
      );
    },
    [cardWidth, cardHeight, selected?._id]
  );

  const getItemLayout = useCallback(
    (_, index: number) => ({
      length: cardWidth + CARD_PADDING * 2,
      offset: (cardWidth + CARD_PADDING * 2) * index - CARD_PADDING,
      index,
    }),
    [cardWidth]
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }
  }, []);

  return (
    <FlatList
      nativeID="custom-scroller"
      ref={flatListRef}
      style={{
        width: '100%',
        height: cardHeight + 18 + 16,
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
      horizontal
      decelerationRate="fast"
      extraData={selected?._id}
      snapToInterval={cardWidth + CARD_PADDING * 2}
      renderItem={renderItem}
      keyExtractor={extractKey}
      data={data}
      initialNumToRender={15}
      getItemLayout={getItemLayout}
    />
  );
}

function extractKey(item: PreparedLocation) {
  return item._id;
}

function LocationSwipeCard({
  location,
  cardWidth,
  cardHeight,
  selected,
}: {
  location: TactileLocation;
  cardWidth: number;
  cardHeight: number;
  selected: boolean;
}) {
  const {
    colors: { primary },
  } = useTheme();

  const { gotoLocation } = useBlockNavigation();
  const { roundness } = useTheme();

  const localizedTitle = useLocalization(
    location.nameLocalized?.full,
    location.name.full
  );
  const { data: imageUrl } = useBlockImageUrl(
    location.image.profile || '',
    'icon_512'
  );

  const directableLocation = useMemo(() => {
    const { street, number, addition, city, geojson } = location || {};

    const coordinates = geojson?.type === 'Point' ? geojson.coordinates : [];
    const [latitude, longitude] = coordinates || [];

    const addressDescription = street
      ? [street, [number ?? '', addition ?? ''].join('')]
          .filter(Boolean)
          .join(' ')
          .trim()
      : '';

    const directableLocation = addressDescription
      ? [localizedTitle, addressDescription, city]
          .filter(Boolean)
          .join(' ')
          .trim()
      : latitude && longitude
      ? { latitude, longitude }
      : (localizedTitle ?? '').trim();

    return directableLocation;
  }, [location]);

  const { url } = location;

  return (
    <View
      style={{
        paddingHorizontal: CARD_PADDING,
        justifyContent: 'flex-end',
        paddingBottom: 18,
        position: 'relative',
      }}
      key={location._id}
    >
      <Card
        key={location._id}
        style={{
          width: cardWidth,
          maxHeight: cardHeight,
          position: 'relative',
        }}
        elevation={4}
        onPress={() => gotoLocation(location._id)}
      >
        <Image
          source={{
            uri: imageUrl || '',
            width: 512,
            height: 512,
          }}
          resizeMode="cover"
          style={{
            maxWidth: '100%',
            width: cardWidth,
            height: cardHeight,
            overflow: 'hidden',
            borderRadius: roundness,
          }}
        />

        <View style={{ position: 'absolute', top: 8, left: 8 }}>
          <PrimaryButton
            icon="information"
            onPress={() => gotoLocation(location._id)}
            style={{ marginRight: 8 }}
            contentStyle={{
              minWidth: 48,
              marginHorizontal: 0,
              paddingHorizontal: 0,
              paddingRight: 10,
            }}
            labelStyle={{
              marginHorizontal: 0,
              paddingHorizontal: 0,
            }}
            compact
          >
            &nbsp;
          </PrimaryButton>
        </View>
        <View
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            flexDirection: 'row',
          }}
        >
          {url ? <SiteButton url={url} /> : null}
          <DirectionsMenu
            label={t('app.actions.directions')}
            icon="directions"
            location={directableLocation}
            style={{ marginLeft: 'auto' }}
          />
        </View>
        <LinearGradient
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 100,
            justifyContent: 'flex-end',
            overflow: 'hidden',
            borderRadius: roundness,
          }}
          colors={['transparent', 'rgba(0, 0, 0, .52)']}
        >
          <Card.Title title={localizedTitle} titleStyle={{ color: 'white' }} />
        </LinearGradient>

        {selected && (
          <View
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              height: 3,
              backgroundColor: primary,
            }}
          />
        )}
      </Card>
    </View>
  );
}

function SiteButton({ url }: { url: string }) {
  const {
    colors: { primary },
  } = useTheme();
  return (
    <AccentButton
      icon="exit-to-app"
      style={{ marginRight: 8 }}
      labelStyle={{
        marginHorizontal: 0,
        paddingHorizontal: 0,
        marginRight: 12,
      }}
      onPress={() => openExternalUrl(url, primary)}
    >
      &nbsp;
    </AccentButton>
  );
}

const PureLocationSwipeCard = React.memo(LocationSwipeCard);
export const MapSwiperOverlay = React.memo(MapSwiperOverlay_);
